import React, { useState } from 'react'

import EfiPay from 'payment-token-efi'
import Cards, { Focused } from 'react-credit-cards-2'

import { useNavigate, useLocation } from 'react-router-dom'

import { Formik, Form, Field, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { Stack, Grid, Divider, Typography, IconButton } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

import { TextInput } from '@/components/Inputs/Text'
import { MaskedInput } from '@/components/Inputs/Masked'

import { useMutationPostPaymentCreditCard } from '@/api/payment/mutations'

import { useToast } from '@/contexts/ToastContext'

import 'react-credit-cards-2/dist/es/styles-compiled.css';

export interface CardFormData {
  cpf: string
  name: string
  card_number: string
  card_expiration: string
  card_cvv: string
  address_street: string
  address_number: string
  address_neighborhood: string
  address_zip: string
  address_city: string
  address_state: string
}

const initialValues: CardFormData = {
  cpf: '',
  name: '',
  card_number: '',
  card_expiration: '',
  card_cvv: '',
  address_street: '',
  address_number: '',
  address_neighborhood: '',
  address_zip: '',
  address_city: '',
  address_state: '',
}
const validationSchema = Yup.object().shape({
  cpf: Yup.string().required('O CPF do titular é obrigatório'),
  name: Yup.string().required('O nome do titular é obrigatório'),
  card_number: Yup.string().required('O número do Cartão é obrigatório'),
  card_expiration: Yup.string()
    .matches(/^\d{2}\/\d{2}$/, 'Formato de expiração inválido (MM/YY)')
    .required('A expiração é obrigatória'),
  card_cvv: Yup.string()
    .matches(/^\d{3}$/, 'O CVV deve ter 3 dígitos')
    .required('O CVV é obrigatório'),
  address_street: Yup.string().required('O campo de Rua é obrigatório'),
  address_number: Yup.string().required('O campo de Número é obrigatório'),
  address_neighborhood: Yup.string().required('O campo de Bairro é obrigatório'),
  address_zip: Yup.string().required('O campo de CEP é obrigatório'),
  address_city: Yup.string().required('O campo de Cidade é obrigatório'),
  address_state: Yup.string().required('O campo de Estado é obrigatório').length(2, 'O Estado deve ter 2 caracteres'),
})


const CardAddPage: React.FC = () => {
  const { show } = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  const { from, productId, paymentMethod } = location.state || {}

  const [focusedField, setFocusedField] = useState<Focused | undefined>(undefined)

  const { mutateAsync: mutatePostPaymentCreditCard } = useMutationPostPaymentCreditCard()

  const handleOnClickGoBack = () => {
    if (from) {
      navigate(from, { state: { productId, paymentMethod } })
      return
    }

    navigate('/wallet', { state: { tab: 'cards' } })
  }

  const handleOnSubmit = async (values: CardFormData, { setSubmitting }: FormikHelpers<CardFormData>) => {
    setSubmitting(true)

    try {
      const cardBrand = await EfiPay.CreditCard.setCardNumber(values.card_number).verifyCardBrand()

      if (cardBrand === 'undefined' || cardBrand === 'unknown') {
        show('O cartão informado é inválido!', {
          severity: 'error',
        })

        return
      }

      const response = await EfiPay.CreditCard.setAccount(process.env.REACT_APP_EFI_ACCOUNT as string)
        .setEnvironment('production')
        .setCreditCardData({
          brand: cardBrand,
          number: values.card_number.replace(/\s/g, ''),
          cvv: values.card_cvv,
          expirationMonth: values.card_expiration.split('/')[0],
          expirationYear: `${new Date().getFullYear().toString().slice(0, 2)}${values.card_expiration.split('/')[1]}`,
          reuse: true,
          holderDocument: values.cpf.replace(/\D/g, ''),
          holderName: values.name,
        })
        .getPaymentToken()

      const { payment_token, card_mask } = response as EfiPay.CreditCard.PaymentTokenResponse

      const { id: cardId } = await mutatePostPaymentCreditCard({
        token: payment_token,
        brand: cardBrand,
        cpf: values.cpf.replace(/\D/g, ''),
        name: values.name,
        card_mask,
        expiration_month: values.card_expiration.split('/')[0],
        expiration_year: values.card_expiration.split('/')[1],
        address_street: values.address_street,
        address_number: values.address_number,
        address_neighborhood: values.address_neighborhood,
        address_zip: values.address_zip.replace(/\D/g, ''),
        address_city: values.address_city,
        address_state: values.address_state,
      })

      show('Cartão adicionado com sucesso!', { severity: 'success' })

      if (from) {
        navigate(from, { state: { productId, paymentMethod, cardId } })
        return
      }

      navigate('/wallet', { state: { tab: 'cards' } })
    } catch (error: unknown) {
      const err = error as EfiPay.CreditCard.ErrorResponse

      if (err.error_description) {
        show(err.error_description, { severity: 'error' })
      } else {
        show('Ocorreu um erro ao adicionar o cartão!', { severity: 'error' })
      }
    } finally {
      setSubmitting(false)
    }
  }

  const getFocusedField = (): Focused | undefined => {
    return focusedField
  }

  return (
    <Stack paddingTop="24px" paddingX="24px" direction="column">
      <Stack
        direction="column"
        spacing={2}
        sx={{
          paddingTop: 'env(safe-area-inset-top)',
        }}
      >
        <Stack position="relative" direction="row" alignItems="center" justifyContent="center">
          <IconButton onClick={handleOnClickGoBack} sx={{ position: 'absolute', left: 0 }}>
            <KeyboardArrowLeft />
          </IconButton>
          <Typography variant="h6" fontWeight="bold">
            Adicionar cartão
          </Typography>
        </Stack>

        <Divider />

        <Formik<CardFormData>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values, submitForm, isSubmitting }) => (
            <Form>
              <Stack direction="column" spacing={3} sx={{ width: '100%' }}>
                <Stack direction="column" alignItems="center">
                  <Cards
                    name={values.name}
                    number={values.card_number}
                    expiry={values.card_expiration}
                    cvc={values.card_cvv}
                    focused={getFocusedField()}
                    placeholders={{ name: 'NOME DO TITULAR' }}
                    locale={{ valid: 'Válido até' }}
                  />
                </Stack>

                <Field
                  component={TextInput}
                  placeholder="CPF do Titular"
                  name="cpf"
                  autoComplete="cc-cpf"
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      mask: '___.___.___-__',
                      replacement: {
                        _: /[0-9]/,
                      },
                    },
                  }}
                />

                <Field
                  component={TextInput}
                  placeholder="Nome do Titular"
                  name="name"
                  autoComplete="cc-name"
                  onFocus={() => setFocusedField('name')}
                  onBlur={() => setFocusedField(undefined)}
                />

                <Field
                  component={TextInput}
                  placeholder="Número do Cartão"
                  name="card_number"
                  autoComplete="cc-number"
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      mask: '____ ____ ____ ____',
                      replacement: {
                        _: /[0-9]/,
                      },
                    },
                  }}
                  onFocus={() => setFocusedField('number')}
                  onBlur={() => setFocusedField(undefined)}
                />

                <Grid container columnGap={2}>
                  <Grid item xs="auto" sx={{ flex: 1 }}>
                    <Field
                      component={TextInput}
                      placeholder="Expiração"
                      name="card_expiration"
                      autoComplete="cc-exp"
                      InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: {
                          mask: '__/__',
                          replacement: {
                            _: /[0-9]/,
                          },
                        },
                      }}
                      fullWidth
                      onFocus={() => setFocusedField('expiry')}
                      onBlur={() => setFocusedField(undefined)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextInput}
                      placeholder="CVV"
                      name="card_cvv"
                      autoComplete="cc-csc"
                      InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: {
                          mask: '___',
                          replacement: {
                            _: /[0-9]/,
                          },
                        },
                      }}
                      onFocus={() => setFocusedField('cvc')}
                      onBlur={() => setFocusedField(undefined)}
                    />
                  </Grid>
                </Grid>

                <Typography variant="subtitle1" fontWeight="bold">
                  Endereço de cobrança
                </Typography>

                <Grid container columnGap={2}>
                  <Grid item xs="auto" sx={{ flex: 1 }}>
                    <Field
                      component={TextInput}
                      placeholder="Endereço"
                      name="address_street"
                      autoComplete="address-street"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextInput}
                      placeholder="Nº"
                      type="number"
                      name="address_number"
                      autoComplete="address-number"
                    />
                  </Grid>
                </Grid>

                <Field
                  component={TextInput}
                  placeholder="Bairro"
                  name="address_neighborhood"
                  autoComplete="address-neighborhood"
                />

                <Field
                  component={TextInput}
                  placeholder="CEP"
                  name="address_zip"
                  autoComplete="address-zip"
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      mask: '_____-___',
                      replacement: {
                        _: /[0-9]/,
                      },
                    },
                  }}
                />

                <Grid container columnGap={2}>
                  <Grid item xs="auto" sx={{ flex: 1 }}>
                    <Field
                      component={TextInput}
                      placeholder="Cidade"
                      name="address_city"
                      autoComplete="address-city"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      component={TextInput}
                      placeholder="Estado"
                      name="address_state"
                      autoComplete="address-state"
                      inputProps={{ maxLength: 2 }}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  sx={{ borderRadius: 20 }}
                >
                  Adicionar
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Stack>
  )
}

export default CardAddPage
