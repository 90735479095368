import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Box, Stack, Typography, Divider, Skeleton } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Wallet, CheckCircle, HourglassEmpty, Error } from '@mui/icons-material'

import { useQueryPaymentCredits, useQueryPaymentHistory } from '@/api/payment/queries'

import { PaymentHistory, PaymentMethods, PaymentStatus } from '@/types/Payment'

const TransactionItem: React.FC<{ transaction: PaymentHistory }> = ({ transaction }) => {
  const getStatusColor = (status: PaymentStatus) => {
    switch (status) {
      case 'COMPLETED':
        return 'success.main'
      case 'PENDING':
        return 'warning.main'
      case 'FAILED':
        return 'error.main'
      default:
        return 'grey.500'
    }
  }

  const getStatusLabel = (status: PaymentStatus) => {
    switch (status) {
      case 'COMPLETED':
        return 'Concluído'
      case 'PENDING':
        return 'Pendente'
      case 'FAILED':
        return 'Falhou'
      default:
        return 'Desconhecido'
    }
  }

  const getStatusIcon = (status: PaymentStatus) => {
    switch (status) {
      case 'COMPLETED':
        return <CheckCircle />
      case 'PENDING':
        return <HourglassEmpty />
      case 'FAILED':
        return <Error />
      default:
        return null
    }
  }

  const getTransactionMethod = (method: PaymentMethods) => {
    switch (method) {
      case 'PIX':
        return 'Pix'
      case 'CREDIT_CARD':
        return 'Cartão de Crédito'
      case 'CREDITS':
        return 'Pagamento'
      default:
        return 'N/A'
    }
  }

  const getTransactionAmount = (method: PaymentMethods, amount: number) => {
    const formattedAmount = Number(amount).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
    switch (method) {
      case 'PIX':
      case 'CREDIT_CARD':
        return formattedAmount
      case 'CREDITS':
        return `-${formattedAmount}`
      default:
        return 'N/A'
    }
  }

  const formattedDate =
    new Date(transaction.created_at).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) +
    ' às ' +
    new Date(transaction.created_at).toLocaleString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    })

  return (
    <Box
      key={transaction.id}
      sx={{
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
        overflow: 'hidden',
        borderRadius: 4,
        height: 125,
      }}
    >
      <Stack direction="column" height="100%" overflow="hidden" borderRadius={4}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
          bgcolor={getStatusColor(transaction.status)}
        >
          <Typography color="white" variant="subtitle2" fontWeight="bold">
            {getStatusLabel(transaction.status)}
          </Typography>
        </Stack>
        <Typography
          variant="body2"
          fontSize="1rem"
          textAlign="center"
          padding={0.5}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {getTransactionMethod(transaction.method)}
        </Typography>
        <Divider />
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} paddingX={2} flex={1}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 50,
              height: 50,
              borderRadius: '50%',
              backgroundColor: getStatusColor(transaction.status),
              color: 'white',
            }}
          >
            {getStatusIcon(transaction.status)}
          </Box>
          <Divider orientation="vertical" flexItem />
          <Stack direction="column" alignItems="flex-start" justifyContent="center" height="100%" spacing={1} flex={1}>
            <Typography variant="body2" fontSize="0.9rem" color="textSecondary">
              <b>Data:</b> {formattedDate}
            </Typography>
            <Typography variant="body2" fontSize="0.9rem" color="textSecondary">
              <b>Valor:</b> {getTransactionAmount(transaction.method, transaction.value)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

const WalletTab: React.FC = () => {
  const navigate = useNavigate()

  const { data: credits, isLoading: isLoadingCredits } = useQueryPaymentCredits()
  const { data: history, isLoading: isLoadingHistory, isError: isErrorHistory } = useQueryPaymentHistory()

  const handleOnClickDeposit = () => {
    navigate('/deposit')
  }

  return (
    <Stack direction="column" width="100%" spacing={2}>
      {isLoadingCredits ? (
        <Skeleton variant="rectangular" width="100%" height={28} />
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Typography variant="subtitle1" fontWeight="bold">
            Saldo atual:
          </Typography>
          <Typography variant="body1">
            {Number(credits?.balance).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }) || 'N/A'}
          </Typography>
        </Stack>
      )}
      <LoadingButton
        startIcon={<Wallet />}
        variant="contained"
        color="primary"
        sx={{ borderRadius: 4 }}
        loading={isLoadingCredits}
        onClick={handleOnClickDeposit}
        disableElevation
        fullWidth
      >
        Depositar
      </LoadingButton>
      <Divider />
      <Typography variant="subtitle1" fontWeight="bold">
        Histórico de transações
      </Typography>
      {isLoadingHistory ? (
        <Stack spacing={2} width="100%">
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={125} sx={{ borderRadius: 4 }} />
          ))}
        </Stack>
      ) : isErrorHistory ? (
        <Stack spacing={2} paddingY={5} width="100%" textAlign="center">
          <Stack direction="column" alignItems="center" spacing={4}>
            <Box component="img" src="/assets/png/server-down.png" width="100%" maxWidth={300} />
            <Stack direction="column" alignItems="center" spacing={1}>
              <Typography variant="h6" color="textSecondary">
                Ops! Algo deu errado.
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Não foi possível carregar o histórico de transações.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      ) : history?.length ? (
        history.map((transaction) => <TransactionItem key={transaction.id} transaction={transaction} />)
      ) : (
        <Stack spacing={2} paddingY={5} width="100%" textAlign="center">
          <Stack direction="column" alignItems="center" spacing={4}>
            <Box component="img" src="/assets/png/empty.png" width="100%" maxWidth={300} />
            <Stack direction="column" alignItems="center" spacing={1}>
              <Typography variant="h6" color="textSecondary">
                Ops! Parece que está vazio.
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Não há transações registradas.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default WalletTab
