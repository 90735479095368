import React, { useEffect } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { App, URLOpenListenerEvent, BackButtonListenerEvent } from '@capacitor/app'

const AppUrlListener: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split(process.env.REACT_APP_URL).pop()

      if (slug) {
        navigate(slug)
      }
    })

    App.addListener('backButton', (_: BackButtonListenerEvent) => {})

    return () => {
      App.removeAllListeners()
    }
  }, [navigate, location.key])

  return null
}

export default AppUrlListener
