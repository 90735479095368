import React, { useContext, useEffect, useState, createContext, ReactNode } from 'react'

import { useLocation } from 'react-router-dom'

import StationModal from '@/components/Modals/StationModal'

import { Station } from '@/types/Station'

type ModalCallback = () => void

interface StationModalContextProps {
  openModal: (station: Station, callback?: ModalCallback) => void
  closeModal: () => void
}

const StationModalContext = createContext<StationModalContextProps>({
  openModal: () => {},
  closeModal: () => {},
})

export const StationModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation()

  const [station, setStation] = useState<Station | null>(null)
  const [callback, setCallback] = useState<ModalCallback | null>(null)

  const openModal = (selectedStation: Station, callback?: ModalCallback) => {
    setStation(selectedStation)

    if (callback) {
      setCallback(() => callback)
    }
  }

  const handleOnClose = () => {
    setStation(null)

    if (callback) {
      callback()
      setCallback(null)
    }
  }

  const closeModal = () => {
    handleOnClose()
  }

  useEffect(() => {
    closeModal()
  }, [location])

  return (
    <StationModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <StationModal station={station} onClose={handleOnClose} />
    </StationModalContext.Provider>
  )
}

export const useStationModal = () => {
  const context = useContext(StationModalContext)

  if (!context) {
    throw new Error('useStationModal must be used within a StationModalProvider')
  }

  return context
}
