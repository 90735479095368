import React from 'react'

import { useRouteError, isRouteErrorResponse } from 'react-router-dom'

import { Container, Stack, Box, Typography } from '@mui/material'

const ErrorPage: React.FC = () => {
  const error = useRouteError()
  let errorMessage: string

  if (isRouteErrorResponse(error)) {
    errorMessage = error.data || error.statusText || 'Infelizmente, não foi possível obter detalhes sobre o erro.'
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else if (typeof error === 'string') {
    errorMessage = error
  } else {
    errorMessage = 'Infelizmente, não foi possível obter detalhes sobre o erro.'
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: 3,
      }}
    >
      <Stack direction="column" alignItems="center" spacing={4}>
        <Box component="img" src="/assets/png/server-down.png" width="100%" maxWidth={300} />
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="h6" color="textSecondary">
            Ops! Ocorreu um erro.
          </Typography>
          <Typography variant="body2" color="textSecondary" fontStyle="italic">
            {errorMessage}
          </Typography>
        </Stack>
      </Stack>
    </Container>
  )
}

export default ErrorPage
