import React, { useEffect, useState } from 'react'

import { Geolocation } from '@capacitor/geolocation'

import { Box, Stack, Typography, Divider } from '@mui/material'

import { Station, ChargingPointStatus } from '@/types/Station'

import { haversineDistance } from '@/utils/map'

const StationItem: React.FC<{
  station: Station
  onClick: (id: number) => void
}> = ({ station, onClick }) => {
  const [distance, setDistance] = useState<number | null>(null)
  const [isCalculating, setIsCalculating] = useState<boolean>(false)
  const [distanceError, setDistanceError] = useState<boolean>(false)

  useEffect(() => {
    const calculateDistance = async () => {
      try {
        setIsCalculating(true)
        const currentLocation = await Geolocation.getCurrentPosition()

        const calculatedDistance = haversineDistance(
          {
            lat: currentLocation.coords.latitude,
            lng: currentLocation.coords.longitude,
          },
          {
            lat: station.latitude,
            lng: station.longitude,
          }
        )

        setDistance(calculatedDistance / 1000)
      } catch (error) {
        setDistanceError(true)
      } finally {
        setIsCalculating(false)
      }
    }

    calculateDistance()
  }, [station.latitude, station.longitude])

  const someChargingPointAvailable = station.charging_points.some(
    (chargingPoint) => chargingPoint.status === ChargingPointStatus.AVAILABLE
  )
  const allChargingPointsInMaintenance = station.charging_points.every(
    (chargingPoint) => chargingPoint.status === ChargingPointStatus.MAINTENANCE
  )

  const status = someChargingPointAvailable
    ? 'Disponível'
    : allChargingPointsInMaintenance
      ? 'Em manutenção'
      : 'Indisponível'

  return (
    <Box
      key={station.id}
      sx={{
        cursor: 'pointer',
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
        padding: 2,
        borderRadius: 4,
        height: 150,
      }}
      onClick={() => onClick(station.id)}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="100%"
        spacing={2}
        sx={{ overflow: 'hidden' }}
      >
        <Stack direction="column" justifyContent="space-between" height="100%" spacing={1} sx={{ minWidth: 0 }}>
          <Stack direction="column">
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {station.name}
            </Typography>
            <Typography
              color={someChargingPointAvailable ? 'success.main' : 'error.main'}
              variant="body1"
              fontWeight="bold"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {status}
            </Typography>
          </Stack>
          <Divider />
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {station.address}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {isCalculating
              ? 'Calculando distância...'
              : distanceError
                ? 'Distância indisponível'
                : `${distance?.toFixed(2) || '...'} km de distância`}
          </Typography>
        </Stack>
        {station.image && (
          <Box
            component="img"
            src={station.image}
            alt={station.name}
            sx={{
              height: '100%',
              aspectRatio: '1/1',
              objectFit: 'cover',
              borderRadius: 2,
              flexShrink: 0,
            }}
          />
        )}
      </Stack>
    </Box>
  )
}

export default StationItem
