import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { HttpError } from '@/api/http'

import {
  postAuthLogin,
  postAuthLogout,
  postAuthRefresh,
  postAuthRegister,
  postAuthLoginSso,
  postAuthPasswordReset,
  postAuthPasswordResetConfirm,
  putAuthPasswordReset,
  postAuthChangeEmail,
  postAuthVerifyEmail,
} from './services'
import {
  PostAuthLoginPayload,
  PostAuthLoginResponse,
  PostAuthLogoutPayload,
  PostAuthLogoutResponse,
  PostAuthRefreshPayload,
  PostAuthRefreshResponse,
  PostAuthRegisterPayload,
  PostAuthRegisterResponse,
  PostAuthLoginSsoPayload,
  PostAuthLoginSsoResponse,
  PostAuthPasswordResetPayload,
  PostAuthPasswordResetResponse,
  PostAuthPasswordResetConfirmPayload,
  PostAuthPasswordResetConfirmResponse,
  PutAuthPasswordResetPayload,
  PutAuthPasswordResetResponse,
  PostAuthChangeEmailPayload,
  PostAuthChangeEmailResponse,
  PostAuthVerifyEmailPayload,
  PostAuthVerifyEmailResponse,
} from './types'

export const useMutationPostAuthLogin = (
  options?: UseMutationOptions<PostAuthLoginResponse, HttpError, PostAuthLoginPayload>
) => {
  return useMutation({ ...options, mutationFn: postAuthLogin })
}

export const useMutationPostAuthLogout = (
  options?: UseMutationOptions<PostAuthLogoutResponse, HttpError, PostAuthLogoutPayload>
) => {
  return useMutation({ ...options, mutationFn: postAuthLogout })
}

export const useMutationPostAuthRefresh = (
  options?: UseMutationOptions<PostAuthRefreshResponse, HttpError, PostAuthRefreshPayload>
) => {
  return useMutation({ ...options, mutationFn: postAuthRefresh })
}

export const useMutationPostAuthRegister = (
  options?: UseMutationOptions<PostAuthRegisterResponse, HttpError, PostAuthRegisterPayload>
) => {
  return useMutation({ ...options, mutationFn: postAuthRegister })
}

export const useMutationPostAuthLoginSso = (
  options?: UseMutationOptions<PostAuthLoginSsoResponse, HttpError, PostAuthLoginSsoPayload>
) => {
  return useMutation({ ...options, mutationFn: postAuthLoginSso })
}

export const useMutationPostAuthPasswordReset = (
  options?: UseMutationOptions<PostAuthPasswordResetResponse, HttpError, PostAuthPasswordResetPayload>
) => {
  return useMutation({ ...options, mutationFn: postAuthPasswordReset })
}

export const useMutationPostAuthPasswordResetConfirm = (
  options?: UseMutationOptions<PostAuthPasswordResetConfirmResponse, HttpError, PostAuthPasswordResetConfirmPayload>
) => {
  return useMutation({ ...options, mutationFn: postAuthPasswordResetConfirm })
}

export const useMutationPutAuthPasswordReset = (
  options?: UseMutationOptions<PutAuthPasswordResetResponse, HttpError, PutAuthPasswordResetPayload>
) => {
  return useMutation({ ...options, mutationFn: putAuthPasswordReset })
}

export const useMutationPostAuthChangeEmail = (
  options?: UseMutationOptions<PostAuthChangeEmailResponse, HttpError, PostAuthChangeEmailPayload>
) => {
  return useMutation({ ...options, mutationFn: postAuthChangeEmail })
}

export const useMutationPostAuthVerifyEmail = (
  options?: UseMutationOptions<PostAuthVerifyEmailResponse, HttpError, PostAuthVerifyEmailPayload>
) => {
  return useMutation({ ...options, mutationFn: postAuthVerifyEmail })
}
