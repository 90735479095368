import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { HttpError } from '@/api/http'

import {
  getPaymentCreditCards,
  getPaymentCreditCard,
  getPaymentCredits,
  getPaymentHistory,
  getPaymentProducts,
} from './services'

import {
  GetPaymentCreditCardsPayload,
  GetPaymentCreditCardsResponse,
  GetPaymentCreditCardPayload,
  GetPaymentCreditCardResponse,
  GetPaymentCreditsResponse,
  GetPaymentHistoryResponse,
  GetPaymentProductPayload,
  GetPaymentProductResponse,
} from './types'

export const useQueryPaymentCreditCards = (
  payload: GetPaymentCreditCardsPayload,
  options?: Omit<UseQueryOptions<GetPaymentCreditCardsResponse, HttpError>, 'queryKey' | 'queryFn'>
) => {
  const queryKey: QueryKey = ['payment-credit-cards', payload]
  const queryFn = () => getPaymentCreditCards(payload)

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}

export const useQueryPaymentCreditCard = (
  payload: GetPaymentCreditCardPayload,
  options?: Omit<UseQueryOptions<GetPaymentCreditCardResponse, HttpError>, 'queryKey' | 'queryFn'>
) => {
  const queryKey: QueryKey = ['payment-credit-card', payload.id]
  const queryFn = () => getPaymentCreditCard(payload)

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}

export const useQueryPaymentCredits = (
  options?: Omit<UseQueryOptions<GetPaymentCreditsResponse, HttpError>, 'queryKey' | 'queryFn'>
) => {
  const queryKey: QueryKey = ['payment-credits']
  const queryFn = () => getPaymentCredits()

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}

export const useQueryPaymentHistory = (
  options?: Omit<UseQueryOptions<GetPaymentHistoryResponse, HttpError>, 'queryKey' | 'queryFn'>
) => {
  const queryKey: QueryKey = ['payment-history']
  const queryFn = () => getPaymentHistory()

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}

export const useQueryPaymentProducts = (
  payload: GetPaymentProductPayload,
  options?: Omit<UseQueryOptions<GetPaymentProductResponse, HttpError>, 'queryKey' | 'queryFn'>
) => {
  const queryKey: QueryKey = ['payment-products', payload]
  const queryFn = () => getPaymentProducts(payload)

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}
