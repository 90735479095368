import React from 'react'

import ReactDOM from 'react-dom/client'

import { ThemeProvider } from '@mui/material'

import { APIProvider } from '@vis.gl/react-google-maps'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { ToastProvider } from '@/contexts/ToastContext'

import App from './App'
import theme from './theme'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      gcTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    },
  },
})

root.render(
  <ThemeProvider theme={theme}>
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <App />
        </ToastProvider>
      </QueryClientProvider>
    </APIProvider>
  </ThemeProvider>
)

reportWebVitals()
