import http from '@/api/http'

import {
  GetPaymentCreditCardsPayload,
  GetPaymentCreditCardsResponse,
  PostPaymentCreditCardPayload,
  PostPaymentCreditCardResponse,
  GetPaymentCreditCardPayload,
  GetPaymentCreditCardResponse,
  DeletePaymentCreditCardPayload,
  PostPaymentCreditCardMakePaymentByIdPayload,
  PostPaymentCreditCardMakePaymentWithCardDataPayload,
  PostPaymentPixMakePaymentPayload,
  PostPaymentPixMakePaymentResponse,
  GetPaymentCreditsResponse,
  GetPaymentHistoryResponse,
  PostPaymentProductPayload,
  PostPaymentProductResponse,
  GetPaymentProductPayload,
  GetPaymentProductResponse,
} from './types'

export const getPaymentCreditCards = async (
  payload: GetPaymentCreditCardsPayload
): Promise<GetPaymentCreditCardsResponse> => {
  const { page, qnt, infinite } = payload

  const response = await http.get<GetPaymentCreditCardsResponse, GetPaymentCreditCardsPayload>(
    '/payment/credit-card/',
    {
      page,
      qnt: infinite ? -1 : qnt,
    }
  )
  return response.data
}

export const postPaymentCreditCard = async (
  payload: PostPaymentCreditCardPayload
): Promise<PostPaymentCreditCardResponse> => {
  const {
    token,
    brand,
    cpf,
    name,
    card_mask,
    expiration_month,
    expiration_year,
    address_street,
    address_number,
    address_neighborhood,
    address_zip,
    address_city,
    address_state,
  } = payload

  const response = await http.post<PostPaymentCreditCardResponse, PostPaymentCreditCardPayload>(
    '/payment/credit-card/',
    {
      token,
      brand,
      name,
      cpf,
      card_mask,
      expiration_month,
      expiration_year,
      address_street,
      address_number,
      address_neighborhood,
      address_zip,
      address_city,
      address_state,
    }
  )
  return response.data
}

export const getPaymentCreditCard = async (
  payload: GetPaymentCreditCardPayload
): Promise<GetPaymentCreditCardResponse> => {
  const { id } = payload

  const response = await http.get<GetPaymentCreditCardResponse>(`/payment/credit-card/${id}/`)
  return response.data
}

export const deletePaymentCreditCard = async (payload: DeletePaymentCreditCardPayload): Promise<void> => {
  const { id } = payload

  await http.delete<void>(`/payment/credit-card/${id}/`)
}

export const postPaymentCreditCardMakePaymentById = async (
  payload: PostPaymentCreditCardMakePaymentByIdPayload
): Promise<void> => {
  const { id, product_id } = payload

  await http.post<void, Omit<PostPaymentCreditCardMakePaymentByIdPayload, 'id'>>(
    `/payment/credit-card/${id}/make-payment/`,
    {
      product_id,
    }
  )
}

export const postPaymentCreditCardMakePaymentWithCardData = async (
  payload: PostPaymentCreditCardMakePaymentWithCardDataPayload
): Promise<void> => {
  const { product_id, card_data, save_card } = payload

  await http.post<void, PostPaymentCreditCardMakePaymentWithCardDataPayload>('/payment/credit-card/make-payment/', {
    product_id,
    card_data,
    save_card,
  })
}

export const postPaymentPixMakePayment = async (
  payload: PostPaymentPixMakePaymentPayload
): Promise<PostPaymentPixMakePaymentResponse> => {
  const { product_id } = payload

  const response = await http.post<PostPaymentPixMakePaymentResponse, PostPaymentPixMakePaymentPayload>(
    '/payment/pix/make-payment/',
    {
      product_id,
    }
  )
  return response.data
}

export const getPaymentCredits = async (): Promise<GetPaymentCreditsResponse> => {
  const response = await http.get<GetPaymentCreditsResponse>('/payment/credits/')
  return response.data
}

export const getPaymentHistory = async (): Promise<GetPaymentHistoryResponse> => {
  const response = await http.get<GetPaymentHistoryResponse>('/payment/history/')
  return response.data
}

export const getPaymentProducts = async (payload: GetPaymentProductPayload): Promise<GetPaymentProductResponse> => {
  const { page, qnt, infinite } = payload

  const response = await http.get<GetPaymentProductResponse, GetPaymentProductPayload>('/payment/products/', {
    page,
    qnt: infinite ? -1 : qnt,
  })
  return response.data
}

export const postPaymentProduct = async (payload: PostPaymentProductPayload): Promise<PostPaymentProductResponse> => {
  const { name, amount, discount } = payload

  const response = await http.post<PostPaymentProductResponse, PostPaymentProductPayload>('/payment/products/', {
    name,
    amount,
    discount,
  })
  return response.data
}
