import React from 'react'

import { Modal, Stack, Typography, Button } from '@mui/material'
import { ErrorOutlineRounded } from '@mui/icons-material'

import { DeleteAccountModalProps } from './types'

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ open, onClose, onDelete }) => {
  return (
    <Modal open={open} onClose={onClose} aria-describedby="delete-account-modal-description">
      <Stack
        direction="column"
        alignItems="center"
        width={350}
        bgcolor="background.paper"
        borderRadius={4}
        padding={2}
        spacing={2}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Stack fontSize={72}>
          <ErrorOutlineRounded color="error" fontSize="inherit" />
        </Stack>
        <Typography id="delete-account-modal-description" textAlign="center">
          Tem certeza de que deseja excluir todos os seus dados? Esta ação não pode ser desfeita.
        </Typography>
        <Stack direction="column" width="100%" spacing={2}>
          <Button color="error" variant="contained" onClick={onDelete} sx={{ borderRadius: 20 }} disableElevation>
            Excluir
          </Button>
          <Button color="primary" onClick={onClose} sx={{ borderRadius: 20 }}>
            Cancelar
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default DeleteAccountModal
