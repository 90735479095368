import React from 'react'

import { useMap } from '@vis.gl/react-google-maps'

import { AppBar, Select, MenuItem, Typography, Stack, FormControl, CircularProgress } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import { Station } from '@/types/Station'

interface SearchBarProps {
  stations: Station[]
  isLoadingStations: boolean
  selectedStation: Station | null
  setSelectedStation: (value: Station | null) => void
}

const SearchBar: React.FC<SearchBarProps> = ({ stations, isLoadingStations, selectedStation, setSelectedStation }) => {
  const map = useMap()

  const handleOnChange = (event: SelectChangeEvent<number>) => {
    const selectedId = Number(event.target.value) as number
    const station = stations.find((s) => s.id === selectedId) || null

    if (station && map) {
      map.moveCamera({
        center: {
          lat: station.latitude,
          lng: station.longitude,
        },
        zoom: 15,
      })
    }

    setSelectedStation(station)
  }

  const StationOption: React.FC<{
    station: Station
  }> = ({ station }) => (
    <Stack
      direction="column"
      spacing={0.5}
      sx={{
        width: '100%',
        paddingY: 1,
      }}
    >
      <Typography variant="body1">{station.name}</Typography>
      <Typography variant="caption" color="text.secondary">
        {station.address}.
        <br />
        {station.city} - {station.state}, {station.zip_code}.
      </Typography>
    </Stack>
  )

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        top: '48px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'transparent',
        paddingTop: 'env(safe-area-inset-top)',
        paddingX: '24px',
        width: '100%',
      }}
    >
      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        sx={{
          padding: 1,
          borderRadius: 4,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(5px)',
          '& fieldset': {
            border: 'none',
          },
        }}
      >
        <Select
          labelId="station-select-label"
          value={selectedStation ? selectedStation.id : ''}
          onChange={handleOnChange}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return (
                <Typography variant="body1" color="text.secondary">
                  Selecione uma estação de recarga
                </Typography>
              )
            }
            const station = stations.find((s) => s.id === selected)
            return station ? station.name : ''
          }}
          MenuProps={{
            PaperProps: {
              elevation: 0,
              sx: {
                borderRadius: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(5px)',
                maxHeight: 300,
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: -15,
              horizontal: 'left',
            },
          }}
          sx={{
            '.MuiSelect-icon': {
              color: 'inherit',
            },
          }}
        >
          {isLoadingStations ? (
            <MenuItem disabled>
              <Stack direction="row" alignItems="center" justifyContent="center" flex={1} spacing={1}>
                <CircularProgress size={20} />
                <Typography variant="body2">Carregando...</Typography>
              </Stack>
            </MenuItem>
          ) : stations.length > 0 ? (
            stations.map((station) => (
              <MenuItem key={station.id} value={station.id}>
                <StationOption station={station} />
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              <Typography variant="body2" color="text.secondary">
                Nenhum resultado encontrado
              </Typography>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </AppBar>
  )
}

export default SearchBar
