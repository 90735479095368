import React, { useState, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { Stack, Box, Typography, Button, Skeleton, IconButton, Divider } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { KeyboardArrowLeft, TimerOutlined, BoltOutlined, AttachMoneyOutlined } from '@mui/icons-material'

import { useQueryStation } from '@/api/stations/queries'
import { useQueryChargingStatus } from '@/api/charging/queries'
import { useQueryPaymentCredits } from '@/api/payment/queries'

import { useMutationPostChargingStart, useMutationPostChargingStop } from '@/api/charging/mutations'

import { useToast } from '@/contexts/ToastContext'

import { ChargingPointStatus } from '@/types/Station'

import { HttpError } from '@/api/http'

const ChargingPointPage: React.FC = () => {
  const params = useParams()
  const { show } = useToast()
  const navigate = useNavigate()

  const stationId = params.stationId as string
  const chargingPointId = params.chargingPointId as string

  const [isLoadingChargingStart, setIsLoadingChargingStart] = useState(false)
  const [isLoadingChargingStop, setIsLoadingChargingStop] = useState(false)

  const { data: paymentCredits, isLoading: isLoadingPaymentCredits } = useQueryPaymentCredits({
    enabled: true,
    retry: false,
  })
  const { data: station, isLoading: isLoadingStation } = useQueryStation(
    {
      id: Number(stationId),
    },
    {
      enabled: Boolean(stationId),
      refetchInterval: 5000,
      retry: false,
    }
  )

  const { mutateAsync: mutatePostChargingStart } = useMutationPostChargingStart()
  const { mutateAsync: mutatePostChargingStop } = useMutationPostChargingStop()

  const chargingPoint = station?.charging_points.find((chargingPoint) => chargingPoint.id === Number(chargingPointId))
  const status = chargingPoint?.status
  const connectorNotifications = chargingPoint?.connector_notifications

  const {
    data: chargingStatus,
    isLoading: isChargingStatusLoading,
    isError: isChargingStatusError,
    error: chargingStatusError,
  } = useQueryChargingStatus(
    {
      charging_point_id: Number(chargingPointId),
    },
    {
      enabled: status === ChargingPointStatus.IN_PROGRESS,
      refetchInterval: 1000,
      retry: false,
    }
  )

  const getElapsedTime = (start: Date, end: Date) => {
    if (!start) {
      return '00h 00m 00s'
    }

    const elapsedTime = end.getTime() - new Date(start).getTime()

    const hours = Math.floor(elapsedTime / 1000 / 60 / 60)
    const minutes = Math.floor((elapsedTime / 1000 / 60) % 60)
    const seconds = Math.floor((elapsedTime / 1000) % 60)

    return `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`
  }

  const handleOnClickStartCharging = async () => {
    setIsLoadingChargingStart(true)

    await mutatePostChargingStart({
      charging_point_id: Number(chargingPointId),
    })
      .then(() => {
        show('Carregamento iniciado com sucesso!', {
          severity: 'success',
        })
      })
      .catch((error: HttpError) => {
        show(error.message || 'Ocorreu um erro ao iniciar o carregamento!', {
          severity: 'error',
        })
      })
      .finally(() => {
        setIsLoadingChargingStart(false)
      })
  }

  const handleOnClickStopCharging = async () => {
    setIsLoadingChargingStop(true)

    await mutatePostChargingStop({
      charging_point_id: Number(chargingPointId),
    })
      .then(() => {
        show('Carregamento finalizado com sucesso!', {
          severity: 'success',
        })
      })
      .catch((error: HttpError) => {
        show(error.message || 'Ocorreu um erro ao parar o carregamento!', {
          severity: 'error',
        })
      })
      .finally(() => {
        setIsLoadingChargingStop(false)
      })
  }

  const handleOnClickGoBack = () => {
    navigate(`/station/${stationId}`)
  }

  useEffect(() => {
    if (isChargingStatusError && chargingStatusError?.status === 406) {
      show(chargingStatusError?.message || 'Ocorreu um erro ao buscar o status do carregamento!', {
        severity: 'error',
      })

      navigate(`/station/${stationId}`)
    }
  }, [isChargingStatusError, chargingStatusError, show, navigate, stationId])

  return (
    <Stack paddingTop="24px" paddingX="24px" sx={{ height: 'calc(100vh - 112px)' }}>
      <Stack
        direction="column"
        spacing={2}
        flexGrow={1}
        sx={{
          paddingTop: 'env(safe-area-inset-top)',
        }}
      >
        <Stack position="relative" direction="row" alignItems="center" justifyContent="center">
          <IconButton onClick={handleOnClickGoBack} sx={{ position: 'absolute', left: 0 }}>
            <KeyboardArrowLeft />
          </IconButton>
          {isLoadingStation ? (
            <Skeleton variant="text" width="50%" height={32} />
          ) : (
            <Typography variant="h6" fontWeight="bold">
              {chargingPoint?.title}
            </Typography>
          )}
        </Stack>
        <Divider />
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={5} flexGrow={1}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} width="100%">
            {isLoadingPaymentCredits ? (
              <Skeleton variant="text" width="100%" height={32} />
            ) : (
              <React.Fragment>
                <Typography variant="subtitle1" fontWeight="bold">
                  Saldo atual:
                </Typography>
                <Typography variant="body1">
                  {Number(paymentCredits?.balance).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }) || 'N/A'}
                </Typography>
              </React.Fragment>
            )}
          </Stack>

          <Stack direction="column" alignItems="center" justifyContent="center" spacing={5} flexGrow={1} width="100%">
            <Box component="img" src="/assets/png/eletric-car-charging.png" width="100%" sx={{ maxWidth: 300 }} />

            {isLoadingStation ? (
              <Stack direction="column" alignItems="center" width="100%">
                <Skeleton variant="text" width="100%" height={32} />
                <Skeleton variant="text" width="100%" height={32} />
              </Stack>
            ) : connectorNotifications ? (
              status === ChargingPointStatus.AVAILABLE ? (
                <Typography variant="subtitle1" fontWeight="bold" textAlign="center">
                  Conector disponível para uso.
                  <br />
                  Conecte o cabo ao veículo para começar.
                </Typography>
              ) : status === ChargingPointStatus.CONNECTED ? (
                <Typography variant="subtitle1" fontWeight="bold" textAlign="center">
                  Carregador conectado.
                  <br />
                  Clique em "Iniciar carregamento" para começar.
                </Typography>
              ) : status === ChargingPointStatus.UNAVAILABLE ? (
                <Typography variant="subtitle1" fontWeight="bold" textAlign="center">
                  Carregamento finalizado.
                  <br />
                  Por favor, desconecte o cabo do veículo.
                </Typography>
              ) : status === ChargingPointStatus.MAINTENANCE ? (
                <Typography variant="subtitle1" fontWeight="bold" textAlign="center">
                  Este carregador está em manutenção.
                  <br />
                  Por favor, utilize um carregador disponível.
                </Typography>
              ) : null
            ) : status === ChargingPointStatus.AVAILABLE ? (
              <Typography variant="subtitle1" fontWeight="bold" textAlign="center">
                Conector disponível para uso.
                <br />
                Clique em "Iniciar carregamento" para começar.
              </Typography>
            ) : status === ChargingPointStatus.MAINTENANCE ? (
              <Typography variant="subtitle1" fontWeight="bold" textAlign="center">
                Este carregador está em manutenção.
                <br />
                Por favor, utilize um carregador disponível.
              </Typography>
            ) : null}

            {status === ChargingPointStatus.IN_PROGRESS ? (
              isChargingStatusLoading ? (
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <Stack key={index} direction="column" alignItems="center" width={100} spacing={1}>
                      <Skeleton variant="circular" width={35} height={35} />
                      <Skeleton variant="text" width={48} height={22} />
                      <Stack direction="column" alignItems="center">
                        <Skeleton variant="text" width={48} height={22} />
                        <Skeleton variant="text" width={72} height={22} />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Stack direction="column" alignItems="center" width={100} spacing={1}>
                    <TimerOutlined fontSize="large" />
                    <Typography variant="subtitle2" fontWeight="bold" textAlign="center">
                      {getElapsedTime(
                        chargingStatus?.start_time ? new Date(chargingStatus.start_time) : new Date(),
                        new Date()
                      )}
                    </Typography>
                    <Typography variant="subtitle2" textAlign="center">
                      Tempo
                      <br />
                      decorrido
                    </Typography>
                  </Stack>
                  <Stack direction="column" alignItems="center" width={100} spacing={1}>
                    <BoltOutlined fontSize="large" />
                    <Typography variant="subtitle2" fontWeight="bold" textAlign="center">
                      {chargingStatus?.kwh_delivered.toFixed(2) || 0} kWh
                    </Typography>
                    <Typography variant="subtitle2" textAlign="center">
                      Energia
                      <br />
                      consumida
                    </Typography>
                  </Stack>
                  <Stack direction="column" alignItems="center" width={100} spacing={1}>
                    <AttachMoneyOutlined fontSize="large" />
                    <Typography variant="subtitle2" fontWeight="bold" textAlign="center">
                      {Number(chargingStatus?.total_cost).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }) || 'N/A'}
                    </Typography>
                    <Typography variant="subtitle2" textAlign="center">
                      Custo
                      <br />
                      atual
                    </Typography>
                  </Stack>
                </Stack>
              )
            ) : null}

            <Stack direction="column" alignItems="center" width="100%" spacing={2}>
              {isLoadingStation ? (
                <Skeleton variant="rounded" width="100%" height={36} sx={{ borderRadius: 4 }} />
              ) : status === ChargingPointStatus.AVAILABLE || status === ChargingPointStatus.CONNECTED ? (
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={handleOnClickStartCharging}
                  sx={{ width: '100%', borderRadius: 4 }}
                  loading={isLoadingChargingStart}
                  disableElevation
                >
                  Iniciar carregamento
                </LoadingButton>
              ) : status === ChargingPointStatus.IN_PROGRESS ? (
                <LoadingButton
                  color="error"
                  variant="contained"
                  onClick={handleOnClickStopCharging}
                  sx={{ width: '100%', borderRadius: 4 }}
                  loading={isLoadingChargingStop}
                  disableElevation
                >
                  Parar carregamento
                </LoadingButton>
              ) : null}
              <Button
                color="primary"
                variant="outlined"
                onClick={handleOnClickGoBack}
                sx={{ width: '100%', borderRadius: 4 }}
                disableElevation
              >
                Voltar
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ChargingPointPage
