import http from '@/api/http'

import { GetStationsPayload, GetStationsResponse, GetStationPayload, GetStationResponse } from './types'

export const getStations = async (payload: GetStationsPayload): Promise<GetStationsResponse> => {
  const { page, qnt, infinite, search } = payload

  const response = await http.get<GetStationsResponse, GetStationsPayload>('/stations/', {
    page,
    qnt: infinite ? -1 : qnt,
    search,
  })
  return response.data
}

export const getStation = async (payload: GetStationPayload): Promise<GetStationResponse> => {
  const { id } = payload

  const response = await http.get<GetStationResponse>(`/stations/${id}/`)
  return response.data
}
