import React from 'react'

import { Outlet, ScrollRestoration } from 'react-router-dom'

import { KeyboardProvider } from '@/contexts/KeyboardContext'
import { StationModalProvider } from '@/contexts/StationModalContext'

import AppUrlListener from '@/components/Router/AppUrlListener'
import ErrorHandler from '@/components/Router/ErrorHandler'

const Root: React.FC = () => {
  return (
    <StationModalProvider>
      <KeyboardProvider>
        <ScrollRestoration />
        <Outlet />
        <AppUrlListener />
        <ErrorHandler />
      </KeyboardProvider>
    </StationModalProvider>
  )
}

export default Root
