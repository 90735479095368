import React from 'react'

import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { Stack, CircularProgress } from '@mui/material'

import { useAuth } from '@/hooks/useAuth'

const ProtectedRoute: React.FC = () => {
  const location = useLocation()
  const { isAuthenticated, isLoading } = useAuth()

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size="2.5rem" />
      </Stack>
    )
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth" replace state={{ from: location }} />
  }

  return <Outlet />
}

export default ProtectedRoute
