import React, { useEffect, useState } from 'react'

import { Alert, Snackbar } from '@mui/material'
import { CheckCircle, Info, Warning, Error } from '@mui/icons-material'

import { ToastProps } from './types'

const Toast: React.FC<ToastProps> = ({ index, message, autoHideDuration, onExited, ...props }) => {
  const verticalOffset = 16 + index * 60

  const severityStyle = {
    success: {
      color: 'white',
      backgroundColor: 'success.main',
    },
    info: {
      color: 'white',
      backgroundColor: 'primary.main',
    },
    warning: {
      color: 'white',
      backgroundColor: 'warning.main',
    },
    error: {
      color: 'white',
      backgroundColor: 'error.main',
    },
  }

  const iconStyle = { color: 'white' }

  const [open, setOpen] = useState(false)

  const handleOnClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Snackbar
      key={message.key}
      open={open}
      onClose={handleOnClose}
      TransitionProps={{ onExited }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={autoHideDuration ?? 5000}
      style={{ paddingTop: 'env(safe-area-inset-top)', top: `${verticalOffset}px` }}
      {...props}
    >
      <Alert
        severity={message.severity}
        onClose={handleOnClose}
        sx={{ borderRadius: 10, alignItems: 'center', ...severityStyle[message.severity] }}
        iconMapping={{
          success: <CheckCircle style={iconStyle} />,
          info: <Info style={iconStyle} />,
          warning: <Warning style={iconStyle} />,
          error: <Error style={iconStyle} />,
        }}
      >
        {message.message}
      </Alert>
    </Snackbar>
  )
}

export default Toast
