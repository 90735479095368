import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Stack, Typography, Divider, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import HistoryTab from '@/modules/Wallet/HistoryTab'
import WalletTab from '@/modules/Wallet/WalletTab'
import CardsTab from '@/modules/Wallet/CardsTab'

interface Tab {
  label: string
  value: string
  Component: React.FC
  disabled?: boolean
  hidden?: boolean
}

const tabs: Tab[] = [
  { label: 'Carteira', value: 'wallet', Component: WalletTab },
  { label: 'Cartões', value: 'cards', Component: CardsTab },
  { label: 'Histórico', value: 'history', Component: HistoryTab },
]

const WalletPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { tab } = location.state || {}

  const [value, setValue] = useState('wallet')

  const handleOnChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)

    navigate('/wallet', { state: { tab: newValue } })
  }

  const renderTabPanel = (tabValue: string, Component: React.FC) => (
    <TabPanel
      key={tabValue}
      value={tabValue}
      sx={
        value === tabValue
          ? {
              display: 'flex',
              padding: 0,
              marginTop: 2,
            }
          : {}
      }
    >
      <Component />
    </TabPanel>
  )

  useEffect(() => {
    if (tab && tabs.some((t) => t.value === tab)) {
      setValue(tab)
    }
  }, [tab])

  return (
    <Stack direction="column" paddingTop="24px" paddingX="24px">
      <Stack
        direction="column"
        sx={{
          paddingTop: 'env(safe-area-inset-top)',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h6" fontWeight="bold">
            Carteira
          </Typography>
        </Stack>
        <Divider sx={{ marginTop: 2 }} />
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList variant="fullWidth" onChange={handleOnChange}>
              {tabs
                .filter((tab) => tab.hidden !== true)
                .map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} disabled={tab.disabled} />
                ))}
            </TabList>
          </Box>
          {tabs.filter((tab) => tab.hidden !== true).map((tab) => renderTabPanel(tab.value, tab.Component))}
        </TabContext>
      </Stack>
    </Stack>
  )
}

export default WalletPage
