import React from 'react'

import { Fab, CircularProgress } from '@mui/material'
import { LocationSearching, MyLocation } from '@mui/icons-material'

interface LocationFabProps {
  isTrackingCurrentLocation: boolean
  isGettingCurrentLocation: boolean
  handleOnClickCurrentLocationClick: () => void
}

const LocationFab: React.FC<LocationFabProps> = ({
  isTrackingCurrentLocation,
  isGettingCurrentLocation,
  handleOnClickCurrentLocationClick,
}) => {
  return (
    <Fab
      color="primary"
      size="medium"
      sx={{
        position: 'fixed',
        bottom: '112px',
        right: '24px',
        boxShadow: 'none',
        backgroundColor: 'rgba(36, 85, 224, 0.8)',
        backdropFilter: 'blur(5px)',
      }}
      disabled={isGettingCurrentLocation}
      onClick={handleOnClickCurrentLocationClick}
    >
      {isGettingCurrentLocation ? (
        <CircularProgress size={24} />
      ) : isTrackingCurrentLocation ? (
        <MyLocation />
      ) : (
        <LocationSearching />
      )}
    </Fab>
  )
}

export default LocationFab
