import http from '@/api/http'

import {
  PostChargingStartPayload,
  PostChargingStartResponse,
  PostChargingStopPayload,
  PostChargingStopResponse,
  GetChargingStatusPayload,
  GetChargingStatusResponse,
  GetChargingHistoryResponse,
} from './types'

export const postChargingStart = async (payload: PostChargingStartPayload): Promise<PostChargingStartResponse> => {
  const { charging_point_id } = payload

  const response = await http.post<PostChargingStartResponse, PostChargingStartPayload>('/charging/start/', {
    charging_point_id,
  })
  return response.data
}

export const postChargingStop = async (payload: PostChargingStopPayload): Promise<PostChargingStopResponse> => {
  const { charging_point_id } = payload

  const response = await http.post<PostChargingStopResponse, PostChargingStopPayload>('/charging/stop/', {
    charging_point_id,
  })
  return response.data
}

export const getChargingStatus = async (payload: GetChargingStatusPayload): Promise<GetChargingStatusResponse> => {
  const { charging_point_id } = payload

  const response = await http.get<GetChargingStatusResponse, GetChargingStatusPayload>(
    `/charging/charging-point/${charging_point_id}/status/`
  )
  return response.data
}

export const getChargingHistory = async (): Promise<GetChargingHistoryResponse> => {
  const response = await http.get<GetChargingHistoryResponse>('/charging/history/')
  return response.data
}
