import React, { useEffect, useState } from 'react'

import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'

import { Stack, Typography, Button, Divider, IconButton, CircularProgress } from '@mui/material'
import { Google, GitHub, LinkedIn, Facebook } from '@mui/icons-material'

import { useQueryAuthLoginSso } from '@/api/auth/queries'

import { useToast } from '@/contexts/ToastContext'

import { useAuth } from '@/hooks/useAuth'

import { HttpError } from '@/api/http'

const AuthPage: React.FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const { show } = useToast()
  const { loginSso } = useAuth()

  const [searchParams] = useSearchParams()

  const [broker, setBroker] = useState<string>()
  const [isAuthenticating, setIsAuthenticating] = useState(false)

  const {
    data: dataAuthLoginSso,
    refetch: refetchAuthLoginSso,
    isLoading: isLoadingAuthLoginSso,
  } = useQueryAuthLoginSso({ broker }, { enabled: !!broker, retry: false })

  const handleOnClickLogin = () => {
    navigate('/auth/login', { state })
  }

  const handleOnClickRegister = () => {
    navigate('/auth/register', { state })
  }

  const handleOnClickLoginSso = (broker: string) => {
    setBroker(broker)
    refetchAuthLoginSso()
  }

  useEffect(() => {
    if (isAuthenticating) return

    if (broker) {
      if (!dataAuthLoginSso) return

      const { url } = dataAuthLoginSso

      window.location.href = url
    }
  }, [dataAuthLoginSso])

  useEffect(() => {
    const code = searchParams.get('code')

    if (code && !isAuthenticating) {
      setIsAuthenticating(true)

      loginSso(code)
        .then(() => {
          navigate(state?.from ? state.from : '/')
        })
        .catch((error: HttpError) => {
          show(error.message || 'Ocorreu um erro ao tentar realizar a autenticação!', {
            severity: 'error',
          })

          navigate('/auth/login', { state })
        })
        .finally(() => {
          setIsAuthenticating(false)
        })
    }
  }, [])

  return (
    <Stack direction="column" alignItems="center" justifyContent="center" spacing={4}>
      <Stack direction="column" alignItems="center" textAlign="center">
        <Typography variant="h6" fontWeight="bold">
          Bem-vindo a MicroRED
        </Typography>
        <Typography variant="subtitle1">Carregue fácil, Ande mais!</Typography>
      </Stack>

      <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ borderRadius: 20 }}
          onClick={handleOnClickLogin}
          disableElevation
        >
          Entrar
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          sx={{ borderRadius: 20 }}
          onClick={handleOnClickRegister}
          disableElevation
        >
          Cadastrar-se
        </Button>
      </Stack>

      {/* <Divider sx={{ width: '100%' }}>OU</Divider>

      <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
        <IconButton color="primary" onClick={() => handleOnClickLoginSso('google')} disabled>
          {isLoadingAuthLoginSso && broker === 'google' ? <CircularProgress size={24} /> : <Google />}
        </IconButton>
        <IconButton color="primary" onClick={() => handleOnClickLoginSso('github')} disabled>
          {isLoadingAuthLoginSso && broker === 'github' ? <CircularProgress size={24} /> : <GitHub />}
        </IconButton>
        <IconButton color="primary" onClick={() => handleOnClickLoginSso('facebook')} disabled>
          {isLoadingAuthLoginSso && broker === 'facebook' ? <CircularProgress size={24} /> : <Facebook />}
        </IconButton>
        <IconButton color="primary" onClick={() => handleOnClickLoginSso('linkedin')} disabled>
          {isLoadingAuthLoginSso && broker === 'linkedin' ? <CircularProgress size={24} /> : <LinkedIn />}
        </IconButton>
      </Stack> */}
    </Stack>
  )
}

export default AuthPage
