export const haversineDistance = (
  coord1: google.maps.LatLng | { lat: number; lng: number },
  coord2: google.maps.LatLng | { lat: number; lng: number }
): number => {
  const toRad = (value: number): number => (value * Math.PI) / 180

  const lat1 = typeof coord1.lat === 'function' ? coord1.lat() : coord1.lat
  const lon1 = typeof coord1.lng === 'function' ? coord1.lng() : coord1.lng

  const lat2 = typeof coord2.lat === 'function' ? coord2.lat() : coord2.lat
  const lon2 = typeof coord2.lng === 'function' ? coord2.lng() : coord2.lng

  const R = 6378137

  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const distance = R * c

  return distance
}
