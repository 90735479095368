import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { HttpError } from '@/api/http'

import { getChargingStatus, getChargingHistory } from './services'
import { GetChargingStatusPayload, GetChargingStatusResponse, GetChargingHistoryResponse } from './types'

export const useQueryChargingStatus = (
  params: GetChargingStatusPayload,
  options?: Omit<UseQueryOptions<GetChargingStatusResponse, HttpError>, 'queryKey' | 'queryFn'>
) => {
  const queryKey: QueryKey = ['charging-status', params]
  const queryFn = () => getChargingStatus(params)

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}

export const useQueryChargingHistory = (
  options?: Omit<UseQueryOptions<GetChargingHistoryResponse, HttpError>, 'queryKey' | 'queryFn'>
) => {
  const queryKey: QueryKey = ['charging-history']
  const queryFn = () => getChargingHistory()

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}
