import { createTheme } from '@mui/material/styles'

import Satoshi from '@/assets/fonts/SatoshiVariable.woff2'
import SatoshiItalic from '@/assets/fonts/SatoshiVariableItalic.woff2'

const theme = createTheme({
  palette: {
    primary: {
      main: '#2455e0',
      light: '#628ce8',
      dark: '#03197b',
      contrastText: '#ffffff',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'Satoshi, Arial, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Satoshi';
          font-style: normal;
          font-display: swap;
          font-weight: 300 900;
          src: url(${Satoshi}) format('woff2');
        }

        @font-face {
          font-family: 'Satoshi';
          font-style: italic;
          font-display: swap;
          font-weight: 300 900;
          src: url(${SatoshiItalic}) format('woff2');
        }
      `,
    },
  },
})

export default theme
