import React, { useRef, useState } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import * as Yup from 'yup'
import { Field, Form, Formik, FormikProps, FormikHelpers } from 'formik'

import { Box, Stack, Typography, Button, FormControl, FormHelperText, Alert } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useMutationPostAuthPasswordReset, useMutationPostAuthPasswordResetConfirm } from '@/api/auth/mutations'

import { TextInput } from '@/components/Inputs/Text'
import { MaskedInput } from '@/components/Inputs/Masked'

import { HttpError } from '@/api/http'
import { MuiOtpInput } from 'mui-one-time-password-input'

export interface ForgotPasswordFormData {
  username: string
}

export interface PinFormData {
  pin: string
}

const initialForgotPasswordValues = {
  username: '',
} as ForgotPasswordFormData

const initialPinValues = {
  pin: '',
} as PinFormData

const forgotPasswordValidationSchema = Yup.object().shape({
  username: Yup.string()
    .test('is-valid-cpf', 'O CPF informado é inválido', (value) => {
      if (!value) return false

      return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value)
    })
    .required('O campo de CPF é obrigatório'),
})

const pinValidationSchema = Yup.object().shape({
  pin: Yup.string().length(4, 'O PIN deve ter 4 dígitos').required('O campo de PIN é obrigatório'),
})

const AuthForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const pinFormRef = useRef<FormikProps<PinFormData>>(null)

  const [username, setUsername] = useState('')

  const [isPinSent, setIsPinSent] = useState(false)
  const [isResendingPin, setIsResendingPin] = useState(false)

  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState<string | null>(null)
  const [forgotPasswordError, setForgotPasswordError] = useState<string | null>(null)

  const { mutateAsync: mutatePostAuthPasswordReset } = useMutationPostAuthPasswordReset()
  const { mutateAsync: mutatePostAuthPasswordResetConfirm } = useMutationPostAuthPasswordResetConfirm()

  const handleOnSubmitForgotPassword = (
    values: ForgotPasswordFormData,
    { setSubmitting }: FormikHelpers<ForgotPasswordFormData>
  ) => {
    const { username } = values

    mutatePostAuthPasswordReset({ username })
      .then(() => {
        setForgotPasswordSuccess('PIN enviado com sucesso!')
        setForgotPasswordError(null)

        setUsername(username)
        setIsPinSent(true)
      })
      .catch((error: HttpError) => {
        setForgotPasswordSuccess(null)
        setForgotPasswordError(
          error.message || 'Ocorreu um erro inesperado ao tentar enviar o PIN! Tente novamente mais tarde.'
        )
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleOnSubmitPin = (values: PinFormData, { setSubmitting }: FormikHelpers<PinFormData>) => {
    mutatePostAuthPasswordResetConfirm({ username, pin: values.pin })
      .then(() => {
        setForgotPasswordSuccess('PIN confirmado com sucesso!')
        setForgotPasswordError(null)

        navigate('/auth/new-password', { state: { username, pin: values.pin, ...state } })
      })
      .catch((error: HttpError) => {
        setForgotPasswordSuccess(null)
        setForgotPasswordError(
          error.message || 'Ocorreu um erro inesperado ao tentar confirmar o PIN! Tente novamente mais tarde.'
        )
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleOnClickResendPin = () => {
    setIsResendingPin(true)

    mutatePostAuthPasswordReset({ username })
      .then(() => {
        setIsResendingPin(false)

        setForgotPasswordSuccess('PIN reenviado com sucesso!')
        setForgotPasswordError(null)
      })
      .catch((error: HttpError) => {
        setIsResendingPin(false)

        setForgotPasswordSuccess(null)
        setForgotPasswordError(
          error.message || 'Ocorreu um erro inesperado ao tentar reenviar o PIN! Tente novamente mais tarde.'
        )
      })
  }

  const handleOnClickGoBack = () => {
    navigate('/auth/login', { state })
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="center" spacing={4}>
      <Stack direction="column" alignItems="center" textAlign="center">
        <Typography variant="h6" fontWeight="bold">
          Esqueci a senha
        </Typography>
        <Typography variant="subtitle1">Carregue fácil, Ande mais!</Typography>
      </Stack>

      {!isPinSent ? (
        <Formik
          key="forgot-password-form"
          initialValues={initialForgotPasswordValues}
          validationSchema={forgotPasswordValidationSchema}
          onSubmit={handleOnSubmitForgotPassword}
        >
          {({ submitForm, isSubmitting }) => (
            <Box component={Form} width="100%">
              <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                {forgotPasswordError ? (
                  <Alert
                    severity="error"
                    sx={{
                      width: '100%',
                      alignItems: 'center',
                      borderRadius: 4,
                    }}
                  >
                    {forgotPasswordError}
                  </Alert>
                ) : null}
                {forgotPasswordSuccess ? (
                  <Alert
                    severity="success"
                    sx={{
                      width: '100%',
                      alignItems: 'center',
                      borderRadius: 4,
                    }}
                  >
                    {forgotPasswordSuccess}
                  </Alert>
                ) : null}

                <Field
                  component={TextInput}
                  placeholder="Digite seu CPF"
                  name="username"
                  autoComplete="cpf"
                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                      mask: '___.___.___-__',
                      replacement: {
                        _: /[0-9]/,
                      },
                    },
                  }}
                />

                <LoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ borderRadius: 20 }}
                  onClick={submitForm}
                  loading={isSubmitting}
                  disableElevation
                >
                  Enviar PIN
                </LoadingButton>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  sx={{ borderRadius: 20 }}
                  onClick={handleOnClickGoBack}
                  disableElevation
                >
                  Voltar a tela de Login
                </Button>
              </Stack>
            </Box>
          )}
        </Formik>
      ) : (
        <Formik
          key="pin-form"
          innerRef={pinFormRef}
          initialValues={initialPinValues}
          validationSchema={pinValidationSchema}
          onSubmit={handleOnSubmitPin}
        >
          {({ submitForm, isSubmitting, errors }) => (
            <Box component={Form} width="100%">
              <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                {forgotPasswordError ? (
                  <Alert
                    severity="error"
                    sx={{
                      width: '100%',
                      alignItems: 'center',
                      borderRadius: 4,
                    }}
                  >
                    {forgotPasswordError}
                  </Alert>
                ) : null}
                {forgotPasswordSuccess ? (
                  <Alert
                    severity="success"
                    sx={{
                      width: '100%',
                      alignItems: 'center',
                      borderRadius: 4,
                    }}
                  >
                    {forgotPasswordSuccess}
                  </Alert>
                ) : null}

                <FormControl>
                  <MuiOtpInput
                    id="pin"
                    aria-label="PIN"
                    aria-describedby="pin-helper-text"
                    value={pinFormRef.current?.values.pin}
                    onChange={(value) => {
                      pinFormRef.current?.setFieldValue('pin', value)
                    }}
                    TextFieldsProps={{
                      placeholder: '•',
                      error: !!errors.pin,
                      size: 'small',
                    }}
                    length={4}
                  />
                  {errors.pin ? (
                    <FormHelperText id="pin-helper-text" error>
                      {errors.pin}
                    </FormHelperText>
                  ) : null}
                </FormControl>

                <LoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ borderRadius: 20 }}
                  onClick={submitForm}
                  loading={isSubmitting}
                  disabled={isResendingPin}
                  disableElevation
                >
                  Confirmar PIN
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  fullWidth
                  sx={{ borderRadius: 20 }}
                  onClick={handleOnClickResendPin}
                  loading={isResendingPin}
                  disableElevation
                >
                  Reenviar PIN
                </LoadingButton>
              </Stack>
            </Box>
          )}
        </Formik>
      )}
    </Stack>
  )
}

export default AuthForgotPasswordPage
