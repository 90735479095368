import React, { useCallback, useEffect, useState } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

import { Formik, Form, Field, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import {
  Stack,
  Avatar,
  Typography,
  Alert,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  Edit,
  BadgeOutlined,
  PermIdentityOutlined,
  EmailOutlined,
  LogoutOutlined,
  Visibility,
  VisibilityOff,
  LockOutlined,
  CancelOutlined,
  RestartAltOutlined,
  PhoneOutlined,
} from '@mui/icons-material'

import { useAuth } from '@/hooks/useAuth'
import { useToast } from '@/contexts/ToastContext'

import { TextInput } from '@/components/Inputs/Text'
import { MaskedInput } from '@/components/Inputs/Masked'

import DeleteAccountModal from '@/components/Modals/DeleteAccountModal'

import { HttpError } from '@/api/http'
import { useQueryAuthVerifyEmailRequest } from '@/api/auth/queries'
import { useMutationPostAuthChangeEmail, useMutationPostAuthVerifyEmail } from '@/api/auth/mutations'

export interface ProfileFormData {
  first_name: string
  last_name: string
  cpf: string
  phone: string
  email: string
}

export interface PasswordFormData {
  current_password: string
  new_password: string
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('O nome é obrigatório'),
  last_name: Yup.string().required('O sobrenome é obrigatório'),
  cpf: Yup.string()
    .test('is-valid-cpf', 'O CPF informado é inválido', (value) => {
      if (!value) return false
      return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value)
    })
    .required('O CPF é obrigatório'),
  email: Yup.string().email('O email informado é inválido').required('O email é obrigatório'),
})

const passwordValidationSchema = Yup.object().shape({
  current_password: Yup.string()
    .min(6, 'A senha atual deve ter no mínimo 6 caracteres')
    .required('A senha atual é obrigatória'),
  new_password: Yup.string()
    .min(6, 'A nova senha deve ter no mínimo 6 caracteres')
    .required('A nova senha é obrigatória'),
})

const ProfilePage: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const { user, updateTokens, updateInfo, updatePassword, deleteAccount, logout, isLoading: isAuthLoading } = useAuth()

  const [isEditing, setIsEditing] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const [submitError, setSubmitError] = useState<string | null>(null)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [isEditingPassword, setIsEditingPassword] = useState(false)

  const {
    refetch: refetchVerifyEmailRequest,
    isLoading: isVerifyEmailRequestLoading,
    isFetching: isVerifyEmailRequestFetching,
    isRefetching: isVerifyEmailRequestRefetching,
  } = useQueryAuthVerifyEmailRequest({ enabled: false })

  const { mutateAsync: mutatePostAuthChangeEmail } = useMutationPostAuthChangeEmail()
  const { mutateAsync: mutatePostAuthVerifyEmail } = useMutationPostAuthVerifyEmail()

  const initialValues: ProfileFormData = {
    first_name: user?.first_name ?? '',
    last_name: user?.last_name ?? '',
    cpf: user?.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') ?? '',
    phone: user?.phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '$1 ($2) $3-$4') ?? '',
    email: user?.email ?? '',
  }

  const handleResendVerifyEmail = async () => {
    refetchVerifyEmailRequest()
      .then(() => {
        toast.show('Email de verificação reenviado com sucesso!', { severity: 'success' })
      })
      .catch(() => {
        toast.show('Ocorreu um erro ao tentar reenviar o email de verificação!', { severity: 'error' })
      })
  }

  const handleOnClickLogout = () => {
    logout()
      .then(() => {
        toast.show('Você saiu da sua conta com sucesso!', {
          severity: 'success',
        })

        navigate('/auth')
      })
      .catch(() => {
        toast.show('Ocorreu um erro ao tentar sair da conta!', {
          severity: 'error',
        })
      })
  }

  const handleToggleEdit = () => {
    setIsEditing((prev) => !prev)
  }

  const handleToggleEditPassword = () => {
    setIsEditingPassword((prev) => !prev)
  }

  const handleUserChange = async (values: ProfileFormData, { setSubmitting }: FormikHelpers<ProfileFormData>) => {
    if (!user) {
      toast.show('Usuário não encontrado.', { severity: 'error' })

      setSubmitting(false)
      return
    }

    setSubmitError(null)
    try {
      await updateInfo({
        first_name: values.first_name,
        last_name: values.last_name,
        cpf: values.cpf,
        phone: values.phone,
        email: values.email,
      })

      toast.show('Perfil atualizado com sucesso!', { severity: 'success' })

      setIsEditing(false)
    } catch (error) {
      setSubmitError((error as HttpError).message || 'Ocorreu um erro ao atualizar o perfil.')
    } finally {
      setSubmitting(false)
    }
  }

  const handlePasswordChange = async (values: PasswordFormData, { setSubmitting }: FormikHelpers<PasswordFormData>) => {
    if (!user) {
      toast.show('Usuário não encontrado.', { severity: 'error' })

      setSubmitting(false)
      return
    }

    setSubmitError(null)
    try {
      await updatePassword(values.current_password, values.new_password)

      toast.show('Senha alterada com sucesso!', { severity: 'success' })

      setIsEditingPassword(false)
    } catch (error) {
      setSubmitError((error as HttpError).message || 'Ocorreu um erro ao alterar a senha.')
    } finally {
      setSubmitting(false)
    }
  }
  const handleDeleteAccount = useCallback(async () => {
    deleteAccount()
      .then(() => {
        toast.show('Sua conta foi excluída com sucesso!', { severity: 'success' })

        navigate('/auth')
      })
      .catch((e) => {
        console.log(e)
        toast.show('Ocorreu um erro ao tentar excluir a conta!', { severity: 'error' })
      })
  }, [deleteAccount])

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword((prev) => !prev)
  }

  const toggleShowNewPassword = () => {
    setShowNewPassword((prev) => !prev)
  }

  useEffect(() => {
    const type = searchParams.get('type')
    const token = searchParams.get('token')

    if (type && token) {
      if (type === 'verify-email') {
        toast.show('Realizando verificação de email...', { severity: 'info' })

        mutatePostAuthVerifyEmail({ token })
          .then(async (response) => {
            const { access, refresh } = response

            await updateTokens(access, refresh)

            toast.show('Email verificado com sucesso!', { severity: 'success' })
          })
          .catch(() => {
            toast.show('Ocorreu um erro ao tentar verificar o email!', { severity: 'error' })
          })
          .finally(() => {
            setSearchParams({})
          })
      } else if (type === 'change-email') {
        toast.show('Realizando alteração de email...', { severity: 'info' })

        mutatePostAuthChangeEmail({ token })
          .then(async (response) => {
            const { access, refresh } = response

            await updateTokens(access, refresh)

            toast.show('Email alterado com sucesso!', { severity: 'success' })
          })
          .catch(() => {
            toast.show('Ocorreu um erro ao tentar alterar o email!', { severity: 'error' })
          })
          .finally(() => {
            setSearchParams({})
          })
      }
    }
  }, [])

  return (
    <Stack paddingTop="24px" paddingX="24px">
      <Stack
        direction="column"
        spacing={4}
        sx={{
          paddingTop: 'env(safe-area-inset-top)',
        }}
      >
        <Stack position="relative" direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h6" fontWeight="bold">
            Perfil
          </Typography>
          {isAuthLoading ? (
            <CircularProgress color="error" size={24} sx={{ position: 'absolute', right: 0 }} />
          ) : (
            <IconButton color="error" onClick={handleOnClickLogout} sx={{ position: 'absolute', right: 0 }}>
              <LogoutOutlined />
            </IconButton>
          )}
        </Stack>

        <Stack
          direction="column"
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Avatar
            alt={user?.name}
            sx={{
              width: '128px',
              height: '128px',
            }}
          />
          <Stack direction="column" alignItems="center">
            <Typography variant="h5">{user?.name}</Typography>
            <Typography variant="body1">Usuário desde 2024</Typography>
          </Stack>
        </Stack>

        {user?.email_verified === false ? (
          <Alert
            severity="warning"
            action={
              isVerifyEmailRequestLoading || isVerifyEmailRequestFetching || isVerifyEmailRequestRefetching ? (
                <CircularProgress color="warning" size={40} sx={{ padding: 1 }} />
              ) : (
                <IconButton color="warning" onClick={handleResendVerifyEmail}>
                  <RestartAltOutlined />
                </IconButton>
              )
            }
            sx={{ borderRadius: 4, alignItems: 'center' }}
          >
            <Typography variant="body2" fontSize="0.8rem" fontWeight="bold">
              Confirmação de e-mail pendente
            </Typography>
          </Alert>
        ) : null}

        {isEditing ? (
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleUserChange}>
            {({ resetForm }) => (
              <Form>
                <Stack direction="row" alignItems="center" justifyContent="space-between" marginBottom={2}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Informações
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Button
                      color="error"
                      variant="text"
                      startIcon={<CancelOutlined />}
                      onClick={() => {
                        resetForm()
                        setIsEditing(false)
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button variant="text" startIcon={<Edit />} type="submit">
                      Salvar
                    </Button>
                  </Stack>
                </Stack>

                {submitError ? (
                  <Alert
                    severity="error"
                    sx={{ width: '100%', alignItems: 'center', borderRadius: 4, marginBottom: 2 }}
                  >
                    {submitError}
                  </Alert>
                ) : null}

                <Stack direction="column" bgcolor="white" borderRadius={4}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingY={1.5}
                    paddingX={2}
                    spacing={1}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <PermIdentityOutlined fontSize="small" />
                    </Stack>
                    <Field
                      component={TextInput}
                      size="normal"
                      name="first_name"
                      placeholder="Nome"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{ flex: 1 }}
                    />
                  </Stack>
                  <Divider />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingY={1.5}
                    paddingX={2}
                    spacing={1}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <PermIdentityOutlined fontSize="small" />
                    </Stack>
                    <Field
                      component={TextInput}
                      size="normal"
                      name="last_name"
                      placeholder="Sobrenome"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{ flex: 1 }}
                    />
                  </Stack>
                  <Divider />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingY={1.5}
                    paddingX={2}
                    spacing={1}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <BadgeOutlined fontSize="small" />
                    </Stack>
                    <Field
                      component={TextInput}
                      size="normal"
                      name="cpf"
                      placeholder="Digite seu CPF"
                      variant="standard"
                      InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: {
                          mask: '___.___.___-__',
                          replacement: {
                            _: /[0-9]/,
                          },
                        },
                        disableUnderline: true,
                      }}
                      sx={{ flex: 1 }}
                    />
                  </Stack>
                  <Divider />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingY={1.5}
                    paddingX={2}
                    spacing={1}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <PhoneOutlined fontSize="small" />
                    </Stack>
                    <Field
                      component={TextInput}
                      size="normal"
                      placeholder="Telefone"
                      variant="standard"
                      name="phone"
                      autoComplete="tel"
                      InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: {
                          mask: '+55 (__) _____-____',
                          replacement: {
                            _: /[0-9]/,
                          },
                        },
                        disableUnderline: true,
                      }}
                      sx={{ flex: 1 }}
                    />
                  </Stack>
                  <Divider />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingY={1.5}
                    paddingX={2}
                    spacing={1}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <EmailOutlined fontSize="small" />
                    </Stack>
                    <Field
                      component={TextInput}
                      size="normal"
                      name="email"
                      placeholder="Email"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      sx={{ flex: 1 }}
                    />
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        ) : (
          <Stack direction="column" spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" fontWeight="bold">
                Informações
              </Typography>
              <Button variant="text" startIcon={<Edit />} onClick={handleToggleEdit}>
                Editar
              </Button>
            </Stack>
            <Stack direction="column" bgcolor="white" borderRadius={4}>
              <Stack direction="row" alignItems="center" padding={2} spacing={2}>
                <Stack direction="row" alignItems="center" spacing={0.5} minWidth={100} width={100}>
                  <PermIdentityOutlined fontSize="small" />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    Nome
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                  {user?.first_name || 'Sem nome cadastrado'}
                </Typography>
              </Stack>
              <Divider />
              <Stack direction="row" alignItems="center" padding={2} spacing={2}>
                <Stack direction="row" alignItems="center" spacing={0.5} minWidth={100} width={100}>
                  <PermIdentityOutlined fontSize="small" />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    Sobrenome
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                  {user?.last_name || 'Sem sobrenome cadastrado'}
                </Typography>
              </Stack>
              <Divider />
              <Stack direction="row" alignItems="center" padding={2} spacing={2}>
                <Stack direction="row" alignItems="center" spacing={0.5} minWidth={100} width={100}>
                  <BadgeOutlined fontSize="small" />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    CPF
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                  {user?.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') || 'Sem CPF cadastrado'}
                </Typography>
              </Stack>
              <Divider />
              <Stack direction="row" alignItems="center" padding={2} spacing={2}>
                <Stack direction="row" alignItems="center" spacing={0.5} minWidth={100} width={100}>
                  <PhoneOutlined fontSize="small" />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    Telefone
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                  {user?.phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '$1 ($2) $3-$4') || 'Sem telefone cadastrado'}
                </Typography>
              </Stack>
              <Divider />
              <Stack direction="row" alignItems="center" padding={2} spacing={2}>
                <Stack direction="row" alignItems="center" spacing={0.5} minWidth={100} width={100}>
                  <EmailOutlined fontSize="small" />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    E-mail
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                  {user?.email || 'Sem e-mail Cadastrado'}
                </Typography>
              </Stack>
              {user?.email_to_change ? (
                <React.Fragment>
                  <Divider />
                  <Stack direction="row" alignItems="center" padding={2} spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={0.5} minWidth={100} width={100}>
                      <EmailOutlined fontSize="small" />
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                      >
                        Novo e-mail
                      </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                      {user?.email_to_change}
                    </Typography>
                  </Stack>
                </React.Fragment>
              ) : null}
            </Stack>
          </Stack>
        )}

        {isEditingPassword ? (
          <Formik
            initialValues={{ current_password: '', new_password: '' }}
            validationSchema={passwordValidationSchema}
            onSubmit={handlePasswordChange}
          >
            {({ resetForm }) => (
              <Form>
                <Stack direction="row" alignItems="center" justifyContent="space-between" marginBottom={2}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Alterar senha
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Button
                      color="error"
                      variant="text"
                      startIcon={<CancelOutlined />}
                      onClick={() => {
                        resetForm()
                        setIsEditingPassword(false)
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button variant="text" startIcon={<Edit />} type="submit">
                      Salvar
                    </Button>
                  </Stack>
                </Stack>

                {submitError ? (
                  <Alert
                    severity="error"
                    sx={{ width: '100%', alignItems: 'center', borderRadius: 4, marginBottom: 2 }}
                  >
                    {submitError}
                  </Alert>
                ) : null}

                <Stack direction="column" bgcolor="white" borderRadius={4}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingY={1.5}
                    paddingX={2}
                    spacing={1}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <LockOutlined fontSize="small" />
                    </Stack>
                    <Field
                      component={TextInput}
                      size="normal"
                      type={showCurrentPassword ? 'text' : 'password'}
                      name="current_password"
                      placeholder="Senha atual"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={toggleShowCurrentPassword} edge="end">
                              {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ flex: 1 }}
                    />
                  </Stack>
                  <Divider />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingY={1.5}
                    paddingX={2}
                    spacing={1}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <LockOutlined fontSize="small" />
                    </Stack>
                    <Field
                      component={TextInput}
                      size="normal"
                      type={showNewPassword ? 'text' : 'password'}
                      name="new_password"
                      placeholder="Nova senha"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={toggleShowNewPassword} edge="end">
                              {showNewPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ flex: 1 }}
                    />
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        ) : (
          <Stack direction="column" spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" fontWeight="bold">
                Alterar Senha
              </Typography>
              <Button variant="text" startIcon={<Edit />} onClick={handleToggleEditPassword}>
                Editar
              </Button>
            </Stack>
            <Stack direction="column" bgcolor="white" borderRadius={4}>
              <Stack direction="row" alignItems="center" padding={2} spacing={2}>
                <Stack direction="row" alignItems="center" spacing={0.5} minWidth={100} width={100}>
                  <LockOutlined fontSize="small" />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    Senha
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                  ••••••••••
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
        <Divider />
        <Button variant="contained" color="error" onClick={() => setOpenDeleteModal(true)} sx={{ borderRadius: 20 }}>
          Excluir minha conta
        </Button>

        <DeleteAccountModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onDelete={handleDeleteAccount}
        />
      </Stack>
    </Stack>
  )
}

export default ProfilePage
