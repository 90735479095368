import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { Box, Stack, Divider, Skeleton, TextField, InputAdornment, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'

import { useQueryStations } from '@/api/stations/queries'

import StationItem from '@/modules/Stations/StationItem'

import { Station } from '@/types/Station'

const StationsPage: React.FC = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')

  const { data: stations, isLoading: isLoadingStations, isError: isErrorStations } = useQueryStations({ search })

  const handleOnClickViewDetails = (stationId: number) => {
    navigate(`/station/${stationId}`)
  }

  return (
    <Stack paddingTop="24px" paddingX="24px" direction="column">
      <Stack
        direction="column"
        spacing={2}
        sx={{
          paddingTop: 'env(safe-area-inset-top)',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h6" fontWeight="bold">
            Estações de recarga
          </Typography>
        </Stack>
        <Divider />
        <TextField
          placeholder="Nome do local, estação ou endereço"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '48px',
              backgroundColor: 'white',
              borderRadius: 4,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          fullWidth
        />

        {isLoadingStations ? (
          <Stack spacing={2} width="100%">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} variant="rounded" width="100%" height={150} sx={{ borderRadius: 4 }} />
            ))}
          </Stack>
        ) : isErrorStations ? (
          <Stack spacing={2} paddingY={5} width="100%" textAlign="center">
            <Stack direction="column" alignItems="center" spacing={4}>
              <Box component="img" src="/assets/png/server-down.png" width="100%" maxWidth={300} />
              <Stack direction="column" alignItems="center" spacing={1}>
                <Typography variant="h6" color="textSecondary">
                  Ops! Ocorreu um erro.
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Não foi possível carregar as estações de recarga.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        ) : stations?.results.length ? (
          <Stack spacing={2} width="100%">
            {stations.results.map((station: Station) => (
              <StationItem key={station.id} station={station} onClick={handleOnClickViewDetails} />
            ))}
          </Stack>
        ) : (
          <Stack spacing={2} paddingY={5} width="100%" textAlign="center">
            <Stack direction="column" alignItems="center" spacing={4}>
              <Box component="img" src="/assets/png/empty.png" width="100%" maxWidth={300} />
              <Stack direction="column" alignItems="center" spacing={1}>
                <Typography variant="h6" color="textSecondary">
                  Ops! Onde estão as estações?
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Nenhuma estação de recarga encontrada!
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export default StationsPage
