/* eslint-disable no-empty */
import { useState, useEffect, useCallback, useRef } from 'react'

import { jwtDecode } from 'jwt-decode'

import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'

import { deleteUser, patchUser } from '@/api/users/services'
import { postAuthLogin, postAuthLoginSso, postAuthRefresh, postAuthLogout } from '@/api/auth/services'
import {
  PostAuthLoginPayload,
  PostAuthLoginSsoPayload,
  PostAuthRefreshPayload,
  PostAuthLogoutPayload,
} from '@/api/auth/types'

interface UserPayload {
  user_id: number
  name: string
  first_name: string
  last_name: string
  cpf: string
  phone: string
  email: string
  email_to_change: string | null
  email_verified: boolean
  token_type: string
  exp: number
  iat: number
  jti: string
}

interface User {
  id: number
  name: string
  first_name: string
  last_name: string
  cpf: string
  phone: string
  email: string
  email_to_change: string | null
  email_verified: boolean
}

interface UseAuthReturn {
  user: User | null
  isAuthenticated: boolean
  login: (username: string, password: string) => Promise<void>
  loginSso: (code: string) => Promise<void>
  updateTokens: (access: string, refresh: string) => Promise<void>
  updateInfo: (newUser: Partial<Omit<User, 'id' | 'name' | 'email_verified'>>) => Promise<void>
  updatePassword: (currentPassword: string, newPassword: string) => Promise<void>
  deleteAccount: () => Promise<void>
  logout: () => Promise<void>
  isLoading: boolean
}

const TOKEN_KEY = 'auth_token'
const REFRESH_KEY = 'auth_refresh_token'

export const useAuth = (): UseAuthReturn => {
  const [user, setUser] = useState<User | null>(null)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const refreshTimerRef = useRef<NodeJS.Timeout | null>(null)

  const decodeToken = useCallback((token: string): User | null => {
    try {
      const decoded: UserPayload = jwtDecode<UserPayload>(token)

      if (decoded.exp && Date.now() >= decoded.exp * 1000) {
        return null
      }

      if (
        !decoded.user_id ||
        !decoded.name ||
        !decoded.first_name ||
        !decoded.last_name ||
        !decoded.cpf ||
        !decoded.phone ||
        !decoded.email ||
        decoded.email_verified === undefined
      ) {
        return null
      }

      return {
        id: decoded.user_id,
        name: decoded.name,
        first_name: decoded.first_name,
        last_name: decoded.last_name,
        cpf: decoded.cpf,
        phone: decoded.phone,
        email: decoded.email,
        email_to_change: decoded.email_to_change,
        email_verified: decoded.email_verified,
      }
    } catch {
      return null
    }
  }, [])

  const scheduleTokenRefresh = useCallback((exp: number) => {
    const currentTime = Date.now()

    const expiryTime = exp * 1000
    const timeout = expiryTime - currentTime - 1200000

    if (refreshTimerRef.current) {
      clearTimeout(refreshTimerRef.current)
    }

    refreshTimerRef.current = setTimeout(() => {
      handleRefreshToken()
    }, timeout)
  }, [])

  const refreshToken = useCallback(async (): Promise<void> => {
    try {
      const result = await SecureStoragePlugin.get({ key: REFRESH_KEY })
      const refresh = result.value

      if (!refresh) {
        throw new Error('Refresh token não encontrado.')
      }

      const payload: PostAuthRefreshPayload = { refresh }
      const response = await postAuthRefresh(payload)

      await updateTokens(response.access, refresh)

      const decoded: UserPayload = jwtDecode<UserPayload>(response.access)
      scheduleTokenRefresh(decoded.exp)
    } catch (error) {
      await logout()
    }
  }, [decodeToken, scheduleTokenRefresh])

  const handleRefreshToken = useCallback(async () => {
    await refreshToken()
  }, [refreshToken])

  const checkAuth = useCallback(async () => {
    let token: string | null = null
    let refresh: string | null = null

    try {
      const tokenResult = await SecureStoragePlugin.get({
        key: TOKEN_KEY,
      })
      token = tokenResult.value
    } catch {
      token = null
    }

    try {
      const refreshResult = await SecureStoragePlugin.get({
        key: REFRESH_KEY,
      })
      refresh = refreshResult.value
    } catch {
      refresh = null
    }

    if (token) {
      const decoded: UserPayload = jwtDecode<UserPayload>(token)

      if (decoded.exp * 1000 > Date.now()) {
        const decodedUser = decodeToken(token)

        if (decodedUser) {
          setUser(decodedUser)
          setIsAuthenticated(true)
          scheduleTokenRefresh(decoded.exp)
        } else {
          setUser(null)
          setIsAuthenticated(false)
        }
      } else if (refresh) {
        try {
          await refreshToken()
        } catch {
          setUser(null)
          setIsAuthenticated(false)
        }
      } else {
        setUser(null)
        setIsAuthenticated(false)
      }
    } else {
      setUser(null)
      setIsAuthenticated(false)
    }

    setIsLoading(false)
  }, [decodeToken, refreshToken, scheduleTokenRefresh])

  const login = useCallback(
    async (username: string, password: string) => {
      setIsLoading(true)

      try {
        await SecureStoragePlugin.remove({ key: TOKEN_KEY })
        await SecureStoragePlugin.remove({ key: REFRESH_KEY })
      } catch {}

      try {
        const payload: PostAuthLoginPayload = { username, password }
        const response = await postAuthLogin(payload)

        await updateTokens(response.access, response.refresh)

        const decoded: UserPayload = jwtDecode<UserPayload>(response.access)
        scheduleTokenRefresh(decoded.exp)
      } catch (error) {
        setUser(null)
        setIsAuthenticated(false)

        throw error
      } finally {
        setIsLoading(false)
      }
    },
    [decodeToken, scheduleTokenRefresh]
  )

  const loginSso = useCallback(
    async (code: string) => {
      setIsLoading(true)

      try {
        await SecureStoragePlugin.remove({ key: TOKEN_KEY })
        await SecureStoragePlugin.remove({ key: REFRESH_KEY })
      } catch {}

      try {
        const payload: PostAuthLoginSsoPayload = { code }
        const response = await postAuthLoginSso(payload)

        await updateTokens(response.access, response.refresh)

        const decoded: UserPayload = jwtDecode<UserPayload>(response.access)
        scheduleTokenRefresh(decoded.exp)
      } catch (error) {
        setUser(null)
        setIsAuthenticated(false)

        throw error
      } finally {
        setIsLoading(false)
      }
    },
    [decodeToken, scheduleTokenRefresh]
  )

  const updateTokens = useCallback(
    async (access: string, refresh: string) => {
      await SecureStoragePlugin.set({
        key: TOKEN_KEY,
        value: access,
      })
      await SecureStoragePlugin.set({
        key: REFRESH_KEY,
        value: refresh,
      })

      const decodedUser = decodeToken(access)

      if (decodedUser) {
        setUser(decodedUser)
        setIsAuthenticated(true)
      } else {
        await SecureStoragePlugin.remove({ key: TOKEN_KEY })
        await SecureStoragePlugin.remove({ key: REFRESH_KEY })

        setUser(null)
        setIsAuthenticated(false)

        throw new Error('Ocorreu um erro interno no servidor! Tente novamente mais tarde.')
      }
    },
    [decodeToken]
  )

  const updateInfo = useCallback(
    async (newUser: Partial<Omit<User, 'id' | 'name' | 'email_verified'>>) => {
      setIsLoading(true)

      if (!user) {
        setIsLoading(false)
        throw new Error('Usuário não autenticado.')
      }

      try {
        const { access, refresh } = await patchUser({
          id: user.id,
          first_name: newUser.first_name,
          last_name: newUser.last_name,
          cpf: newUser.cpf,
          phone: newUser.phone,
          email: newUser.email,
        })

        await updateTokens(access, refresh)
      } finally {
        setIsLoading(false)
      }
    },
    [user, updateTokens]
  )

  const updatePassword = useCallback(
    async (currentPassword: string, newPassword: string) => {
      setIsLoading(true)

      if (!user) {
        setIsLoading(false)

        throw new Error('Usuário não autenticado.')
      }

      try {
        const { access, refresh } = await patchUser({
          id: user.id,
          old_password: currentPassword,
          password: newPassword,
        })

        await updateTokens(access, refresh)
      } finally {
        setIsLoading(false)
      }
    },
    [user, updateTokens]
  )

  const logout = useCallback(async () => {
    setIsLoading(true)

    let refresh: string | null = null
    try {
      const result = await SecureStoragePlugin.get({
        key: REFRESH_KEY,
      })

      refresh = result.value
    } catch {
      refresh = null
    }

    try {
      if (refresh) {
        const payload: PostAuthLogoutPayload = { refresh }
        await postAuthLogout(payload)
      }

      await SecureStoragePlugin.remove({ key: TOKEN_KEY })
      await SecureStoragePlugin.remove({ key: REFRESH_KEY })

      setUser(null)
      setIsAuthenticated(false)

      if (refreshTimerRef.current) {
        clearTimeout(refreshTimerRef.current)
        refreshTimerRef.current = null
      }
    } catch {
      await SecureStoragePlugin.remove({ key: TOKEN_KEY })
      await SecureStoragePlugin.remove({ key: REFRESH_KEY })

      setUser(null)
      setIsAuthenticated(false)

      if (refreshTimerRef.current) {
        clearTimeout(refreshTimerRef.current)
        refreshTimerRef.current = null
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  const deleteAccount = useCallback(async () => {
    setIsLoading(true)

    if (!user) {
      setIsLoading(false)
      throw new Error('Usuário não autenticado.')
    }

    try {
      await deleteUser({
        id: user.id,
      })
    } finally {
      await SecureStoragePlugin.remove({ key: TOKEN_KEY })
      await SecureStoragePlugin.remove({ key: REFRESH_KEY })
      setIsLoading(false)
    }
  }, [user, deleteUser])

  useEffect(() => {
    checkAuth()

    return () => {
      if (refreshTimerRef.current) {
        clearTimeout(refreshTimerRef.current)
      }
    }
  }, [checkAuth])

  return {
    user,
    isAuthenticated,
    login,
    loginSso,
    updateTokens,
    updateInfo,
    updatePassword,
    deleteAccount,
    logout,
    isLoading,
  }
}
