import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Box, Stack, Typography, Divider, Skeleton, IconButton, Tooltip, Button, CircularProgress } from '@mui/material'
import { DeleteOutlineOutlined, AddOutlined } from '@mui/icons-material'

import { HttpError } from '@/api/http'
import { useQueryPaymentCreditCards } from '@/api/payment/queries'
import { useMutationDeletePaymentCreditCard } from '@/api/payment/mutations'

import { PaymentCreditCard } from '@/types/Payment'

import { useToast } from '@/contexts/ToastContext'

const CardItem: React.FC<{
  card: PaymentCreditCard
  onDelete: (cardId: number) => void
  isDeleting: boolean
}> = ({ card, onDelete, isDeleting }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      border="1px solid #e0e0e0"
      bgcolor="white"
      height={80}
      padding={2}
      borderRadius={2}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box
          component="img"
          src={`/assets/png/card-brands/${card.brand.toLowerCase()}.png`}
          alt={card.brand}
          height={45}
        />
        <Stack direction="column" spacing={1}>
          <Typography variant="body2" color="textSecondary">
            {card.card_mask.replace(/(.{4})/g, '$1 ')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Vencimento: {card.expiration_month}/{card.expiration_year}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Tooltip title="Remover">
          <span>
            <IconButton color="error" onClick={() => onDelete(card.id)} disabled={isDeleting}>
              {isDeleting ? <CircularProgress size={24} /> : <DeleteOutlineOutlined />}
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

const CardsTab: React.FC = () => {
  const navigate = useNavigate()
  const { show } = useToast()

  const { data: creditCards, isLoading, isError, refetch } = useQueryPaymentCreditCards({ infinite: true })

  const { mutateAsync: mutateDeletePaymentCreditCard } = useMutationDeletePaymentCreditCard()

  const [deletingCardIds, setDeletingCardIds] = React.useState<number[]>([])

  const handleAddCard = () => {
    navigate('/card/add')
  }

  const handleDeleteCard = (cardId: number) => {
    setDeletingCardIds((prev) => [...prev, cardId])

    mutateDeletePaymentCreditCard({ id: cardId })
      .then(() => {
        refetch()

        show('O cartão foi removido com sucesso!', {
          severity: 'success',
        })
      })
      .catch((error: HttpError) => {
        show(error.message || 'Ocorreu um erro ao remover o cartão.', {
          severity: 'error',
        })
      })
      .finally(() => {
        setDeletingCardIds((prev) => prev.filter((id) => id !== cardId))
      })
  }

  return (
    <Stack direction="column" width="100%" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight="bold">
          Meus cartões
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddOutlined />}
          onClick={handleAddCard}
          disableElevation
          sx={{ borderRadius: 4 }}
        >
          Adicionar
        </Button>
      </Stack>
      <Divider />
      {isLoading ? (
        <Stack spacing={2} width="100%">
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} variant="rectangular" width="100%" height={80} sx={{ borderRadius: 2 }} />
          ))}
        </Stack>
      ) : isError ? (
        <Stack spacing={2} paddingY={5} width="100%" textAlign="center">
          <Stack direction="column" alignItems="center" spacing={4}>
            <Box component="img" src="/assets/png/server-down.png" width="100%" maxWidth={300} />
            <Stack direction="column" alignItems="center" spacing={1}>
              <Typography variant="h6" color="textSecondary">
                Ops! Ocorreu um erro.
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Não foi possível carregar seus cartões de crédito.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      ) : creditCards?.results?.length ? (
        <Stack spacing={2} width="100%">
          {creditCards.results.map((card) => (
            <CardItem
              key={card.id}
              card={card}
              onDelete={handleDeleteCard}
              isDeleting={deletingCardIds.includes(card.id)}
            />
          ))}
        </Stack>
      ) : (
        <Stack spacing={2} paddingY={5} width="100%" textAlign="center">
          <Stack direction="column" alignItems="center" spacing={4}>
            <Box component="img" src="/assets/png/empty.png" width="100%" maxWidth={300} />
            <Stack direction="column" alignItems="center" spacing={1}>
              <Typography variant="h6" color="textSecondary">
                Ops! Parece que você não tem cartões cadastrados.
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Adicione um cartão para começar.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default CardsTab
