import React, { useContext, useEffect, useState, createContext, ReactNode } from 'react'

import { Capacitor } from '@capacitor/core'
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard'

interface KeyboardContextProps {
  isKeyboardVisible: boolean
}

const KeyboardContext = createContext<KeyboardContextProps>({
  isKeyboardVisible: false,
})

export const KeyboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState<boolean>(false)

  useEffect(() => {
    const handleKeyboardWillShow = (_: KeyboardInfo) => {
      setIsKeyboardVisible(true)
    }

    const handleKeyboardDidShow = (_: KeyboardInfo) => {
      setIsKeyboardVisible(true)
    }

    const handleKeyboardWillHide = () => {
      setIsKeyboardVisible(false)
    }

    const handleKeyboardDidHide = () => {
      setIsKeyboardVisible(false)
    }

    if (Capacitor.getPlatform() !== 'web') {
      Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow)
      Keyboard.addListener('keyboardDidShow', handleKeyboardDidShow)
      Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide)
      Keyboard.addListener('keyboardDidHide', handleKeyboardDidHide)

      return () => {
        Keyboard.removeAllListeners()
      }
    }
  }, [])

  return <KeyboardContext.Provider value={{ isKeyboardVisible }}>{children}</KeyboardContext.Provider>
}

export const useKeyboard = () => {
  const context = useContext(KeyboardContext)

  if (context === undefined) {
    throw new Error('useKeyboard must be used within a KeyboardProvider')
  }

  return context
}
