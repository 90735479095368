import React from 'react'

import { useNavigate, Outlet, useLocation } from 'react-router-dom'

import { Stack, Box, Container, CssBaseline } from '@mui/material'

const AuthTemplate: React.FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const handleOnClickLogo = () => {
    navigate('/auth', { state })
  }

  return (
    <Stack>
      <CssBaseline />

      <Box
        width="100%"
        height="300px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 'env(safe-area-inset-top)',
          background: (theme) =>
            `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          paddingBottom: 6,
        }}
      >
        <Box
          component="img"
          src="/assets/svg/logos/logo-text-w.svg"
          alt="MicroRed Logo"
          sx={{
            display: 'block',
            width: '50%',
            maxWidth: 200,
            cursor: 'pointer',
          }}
          onClick={handleOnClickLogo}
        />
      </Box>

      <Container maxWidth="xs">
        <Box
          component="main"
          sx={{
            backgroundColor: 'white',
            borderRadius: 6,
            padding: 4,
            marginTop: -6,
          }}
        >
          <Outlet />
        </Box>
      </Container>
    </Stack>
  )
}

export default AuthTemplate
