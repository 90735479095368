import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { HttpError } from '@/api/http'

import { postChargingStart, postChargingStop } from './services'
import {
  PostChargingStartPayload,
  PostChargingStartResponse,
  PostChargingStopPayload,
  PostChargingStopResponse,
} from './types'

export const useMutationPostChargingStart = (
  options?: UseMutationOptions<PostChargingStartResponse, HttpError, PostChargingStartPayload>
) => {
  return useMutation({ ...options, mutationFn: postChargingStart })
}

export const useMutationPostChargingStop = (
  options?: UseMutationOptions<PostChargingStopResponse, HttpError, PostChargingStopPayload>
) => {
  return useMutation({ ...options, mutationFn: postChargingStop })
}
