import React, { useState } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import * as Yup from 'yup'
import { Field, Form, Formik, FormikHelpers } from 'formik'

import { Box, Stack, Typography, Button, Alert, IconButton, InputAdornment } from '@mui/material'
import { CheckCircleOutline, Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

import { useMutationPutAuthPasswordReset } from '@/api/auth/mutations'

import { TextInput } from '@/components/Inputs/Text'

import { HttpError } from '@/api/http'

export interface NewPasswordFormData {
  password: string
  confirmPassword: string
}

const initialNewPasswordValues = {
  password: '',
  confirmPassword: '',
} as NewPasswordFormData

const newPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().min(6, 'A senha deve ter no mínimo 6 caracteres').required('O campo de senha é obrigatório'),
  confirmPassword: Yup.string()
    .min(6, 'A senha deve ter no mínimo 6 caracteres')
    .oneOf([Yup.ref('password'), undefined], 'As senhas devem ser iguais')
    .required('O campo de confirmação de senha é obrigatório'),
})

const AuthNewPasswordPage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [passwordChanged, setPasswordChanged] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState<string | null>(null)

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const { username, pin } = (location.state || {}) as {
    username: string
    pin: string
  }

  const { mutateAsync: mutatePutAuthPasswordReset } = useMutationPutAuthPasswordReset()

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev)
  }

  const handleOnSubmitNewPassword = (
    values: NewPasswordFormData,
    { setSubmitting }: FormikHelpers<NewPasswordFormData>
  ) => {
    const { password } = values

    if (!username || !pin) {
      navigate('/auth/forgot-password', { state: location.state })
    }

    mutatePutAuthPasswordReset({ username, pin, password })
      .then(() => {
        setPasswordChanged(true)
      })
      .catch((error: HttpError) => {
        setNewPasswordError(error.message || 'Ocorreu um erro ao alterar a senha! Tente novamente mais tarde.')
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleOnClickLogin = () => {
    navigate('/auth/login', { state: location.state })
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="center" spacing={4}>
      <Stack direction="column" alignItems="center" textAlign="center">
        <Typography variant="h6" fontWeight="bold">
          Definir nova senha
        </Typography>
        <Typography variant="subtitle1">Carregue fácil, Ande mais!</Typography>
      </Stack>

      {passwordChanged ? (
        <Stack direction="column" alignItems="center" spacing={2}>
          <CheckCircleOutline sx={{ fontSize: 80, color: 'success.main' }} />
          <Typography variant="h6" fontWeight="bold">
            Senha alterada com sucesso!
          </Typography>
          <Typography variant="subtitle1" textAlign="center">
            Sua senha foi alterada com sucesso. Você já pode fazer login.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ borderRadius: 20 }}
            onClick={handleOnClickLogin}
            disableElevation
          >
            Fazer login
          </Button>
        </Stack>
      ) : (
        <Formik
          initialValues={initialNewPasswordValues}
          validationSchema={newPasswordValidationSchema}
          onSubmit={handleOnSubmitNewPassword}
        >
          {({ submitForm, isSubmitting }) => (
            <Box component={Form} width="100%">
              <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                {newPasswordError ? (
                  <Alert
                    severity="error"
                    sx={{
                      width: '100%',
                      alignItems: 'center',
                      borderRadius: 4,
                    }}
                  >
                    {newPasswordError}
                  </Alert>
                ) : null}

                <Field
                  component={TextInput}
                  placeholder="Digite sua nova senha"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  component={TextInput}
                  placeholder="Confirme sua Nova Senha"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleToggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <LoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ borderRadius: 20 }}
                  onClick={submitForm}
                  loading={isSubmitting}
                  disableElevation
                >
                  Confirmar nova senha
                </LoadingButton>
              </Stack>
            </Box>
          )}
        </Formik>
      )}
    </Stack>
  )
}

export default AuthNewPasswordPage
