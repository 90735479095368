import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Box,
  Stack,
  Typography,
  Divider,
  Skeleton,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Modal,
  TextField,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { CreditCard, Pix, AddOutlined, KeyboardArrowLeft, ContentCopy } from '@mui/icons-material'
import { useQueryPaymentProducts, useQueryPaymentCreditCards } from '@/api/payment/queries'
import {
  useMutationPostPaymentCreditCardMakePaymentById,
  useMutationPostPaymentPixMakePayment,
} from '@/api/payment/mutations'
import { PaymentProduct, PaymentCreditCard } from '@/types/Payment'
import { useToast } from '@/contexts/ToastContext'

const CardItem: React.FC<{
  card: PaymentCreditCard
  selectedCardId: number | null
  onSelect: (cardId: number) => void
}> = ({ card, selectedCardId, onSelect }) => {
  const isSelected = selectedCardId === card.id

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      border="1px solid #e0e0e0"
      bgcolor={isSelected ? 'grey.200' : 'white'}
      height={80}
      padding={2}
      borderRadius={2}
      onClick={() => onSelect(card.id)}
      sx={{ cursor: 'pointer' }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box
          component="img"
          src={`/assets/png/card-brands/${card.brand.toLowerCase()}.png`}
          alt={card.brand}
          height={45}
        />
        <Stack direction="column" spacing={1}>
          <Typography variant="body2" color="textSecondary">
            {card.card_mask.replace(/(.{4})/g, '$1 ')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Vencimento: {card.expiration_month}/{card.expiration_year}
          </Typography>
        </Stack>
      </Stack>
      {isSelected && (
        <Typography variant="body2" color="primary">
          Selecionado
        </Typography>
      )}
    </Stack>
  )
}

const ErrorDisplay: React.FC<{ message: string }> = ({ message }) => (
  <Stack spacing={2} paddingY={5} width="100%" textAlign="center">
    <Stack direction="column" alignItems="center" spacing={4}>
      <Box component="img" src="/assets/png/server-down.png" width="100%" maxWidth={300} />
      <Stack direction="column" alignItems="center" spacing={1}>
        <Typography variant="h6" color="textSecondary">
          Ops! Algo deu errado.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {message}
        </Typography>
      </Stack>
    </Stack>
  </Stack>
)

const EmptyState: React.FC<{ title: string; message: string; imageSrc: string }> = ({ title, message, imageSrc }) => (
  <Stack spacing={2} paddingY={5} width="100%" textAlign="center">
    <Stack direction="column" alignItems="center" spacing={4}>
      <Box component="img" src={imageSrc} width="100%" maxWidth={300} />
      <Stack direction="column" alignItems="center" spacing={1}>
        <Typography variant="h6" color="textSecondary">
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {message}
        </Typography>
      </Stack>
    </Stack>
  </Stack>
)

const OrderSummary: React.FC<{ product: PaymentProduct }> = ({ product }) => {
  const discountedAmount = convertProductToDiscountedAmount(product)
  const bonusAmount = convertProductToBonusAmount(product)

  return (
    <>
      {product.discount > 0 && (
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2">Valor do depósito</Typography>
            <Typography variant="body2">
              {Number(product.amount).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2">Valor do desconto</Typography>
            <Typography variant="body2">
              -
              {Number(bonusAmount).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Stack>
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2">Valor a pagar</Typography>
        <Typography variant="body2">
          {Number(discountedAmount).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>
      </Stack>
    </>
  )
}

const convertProductToDiscountedAmount = (product?: PaymentProduct) => {
  if (!product) {
    return 0
  }

  return product.discount > 0 ? product.amount * (1 - product.discount) : product.amount
}

const convertProductToBonusAmount = (product?: PaymentProduct) => {
  if (!product) {
    return 0
  }

  return product.amount - convertProductToDiscountedAmount(product)
}

const DepositPage: React.FC = () => {
  const { show } = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    productId: initialProductId,
    paymentMethod: initialPaymentMethod,
    cardId: initialCardId,
  } = location.state || {}

  const [selectedProductId, setSelectedProductId] = useState<number | null>(null)
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null)
  const [showCardSelection, setShowCardSelection] = useState(false)
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const [paymentCode, setPaymentCode] = useState('')
  const [paymentImage, setPaymentImage] = useState('')

  const {
    data: products,
    isLoading: isLoadingProducts,
    isError: isErrorProducts,
  } = useQueryPaymentProducts({ infinite: true })
  const {
    data: creditCards,
    isLoading: isLoadingCards,
    isError: isErrorCards,
  } = useQueryPaymentCreditCards({ infinite: true })

  const { mutateAsync: mutatePostPaymentCreditCardMakePaymentById } = useMutationPostPaymentCreditCardMakePaymentById()
  const { mutateAsync: mutatePostPaymentPixMakePayment } = useMutationPostPaymentPixMakePayment()

  const handleOnClickGoBack = () => {
    navigate('/wallet', { state: { tab: 'wallet' } })
  }

  const handleProductSelect = (event: React.MouseEvent<HTMLElement> | null, newProductId: number | null) => {
    setSelectedProductId(newProductId)
    setPaymentMethod(null)
    setShowCardSelection(false)
    setSelectedCardId(null)
  }

  const handlePaymentMethodChange = (event: React.MouseEvent<HTMLElement> | null, newMethod: string | null) => {
    setPaymentMethod(newMethod)
    setSelectedCardId(null)
    setShowCardSelection(newMethod === 'credit_card')
  }

  const handleAddCard = () => {
    navigate('/card/add', {
      state: { from: '/deposit', productId: selectedProductId, paymentMethod },
    })
  }

  const handleConfirmPaymentPix = async () => {
    if (!selectedProductId) {
      show('Selecione um produto para continuar!', {
        severity: 'error',
      })
      return
    }

    setSubmitting(true)

    try {
      const { code, image } = await mutatePostPaymentPixMakePayment({
        product_id: selectedProductId,
      })

      setPaymentCode(code)
      setPaymentImage(image)
      setOpenModal(true)

      show('Código de pagamento gerado com sucesso!', {
        severity: 'success',
      })
    } catch (error) {
      show('Ocorreu um erro ao realizar o pagamento!', {
        severity: 'error',
      })
    } finally {
      setSubmitting(false)
    }
  }

  const handleConfirmPaymentCreditCard = async () => {
    if (!selectedCardId || !selectedProductId) {
      show('Selecione um cartão e um produto para continuar!', {
        severity: 'error',
      })
      return
    }

    setSubmitting(true)

    try {
      await mutatePostPaymentCreditCardMakePaymentById({
        id: selectedCardId,
        product_id: selectedProductId,
      })

      show('Pagamento realizado com sucesso!', {
        severity: 'success',
      })

      navigate('/wallet', { state: { tab: 'wallet' } })
    } catch (error) {
      show('Ocorreu um erro ao realizar o pagamento!', {
        severity: 'error',
      })
    } finally {
      setSubmitting(false)
    }
  }

  const handleConfirmPayment = async () => {
    if (paymentMethod === 'pix') {
      await handleConfirmPaymentPix()
    } else if (paymentMethod === 'credit_card') {
      await handleConfirmPaymentCreditCard()
    } else {
      show('Selecione um método de pagamento.', {
        severity: 'error',
      })
    }
  }

  const handleCopyCode = () => {
    navigator.clipboard.writeText(paymentCode)
    show('Código copiado para a área de transferência!', {
      severity: 'success',
    })
  }

  useEffect(() => {
    if (initialProductId) {
      handleProductSelect(null, initialProductId)
    }

    if (initialPaymentMethod) {
      handlePaymentMethodChange(null, initialPaymentMethod)
    }

    if (initialCardId) {
      setSelectedCardId(initialCardId)
    }
  }, [initialProductId, initialPaymentMethod])

  const selectedProduct = products?.results.find((product) => product.id === selectedProductId)

  return (
    <Stack paddingTop="24px" paddingX="24px" direction="column">
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 350,
            maxWidth: '100%',
            bgcolor: 'background.paper',
            borderRadius: 4,
            padding: 4,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Código de Pagamento Gerado
          </Typography>
          <TextField
            label="Código"
            value={paymentCode}
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <IconButton onClick={handleCopyCode}>
                  <ContentCopy />
                </IconButton>
              ),
            }}
          />
          {paymentImage && <Box component="img" src={paymentImage} alt="Imagem do Pagamento" sx={{ width: '100%' }} />}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModal(false)}
            fullWidth
            disableElevation
            sx={{ borderRadius: 4 }}
          >
            Fechar
          </Button>
        </Box>
      </Modal>

      <Stack
        direction="column"
        spacing={2}
        sx={{
          paddingTop: 'env(safe-area-inset-top)',
        }}
      >
        <Stack position="relative" direction="row" alignItems="center" justifyContent="center">
          <IconButton onClick={handleOnClickGoBack} sx={{ position: 'absolute', left: 0 }}>
            <KeyboardArrowLeft />
          </IconButton>
          <Typography variant="h6" fontWeight="bold">
            Depositar
          </Typography>
        </Stack>
        <Divider />
        <Typography variant="h6" fontWeight="bold">
          Selecione um valor de depósito
        </Typography>
        {isLoadingProducts ? (
          <Skeleton variant="rectangular" width="100%" height={200} sx={{ borderRadius: 1 }} />
        ) : isErrorProducts ? (
          <ErrorDisplay message="Não foi possível carregar os valores de depósito." />
        ) : (
          <ToggleButtonGroup
            value={selectedProductId}
            exclusive
            onChange={handleProductSelect}
            aria-label="Seleção de Produto"
            orientation="vertical"
            fullWidth
          >
            {products?.results.map((product: PaymentProduct) => {
              const discountedAmount = convertProductToDiscountedAmount(product)
              const bonusAmount = convertProductToBonusAmount(product)

              return (
                <ToggleButton
                  key={`product-${product.id}`}
                  value={product.id}
                  aria-label={product.name}
                  sx={{
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    <Stack direction="row" alignItems="end" spacing={0.5}>
                      {product.discount > 0 && (
                        <>
                          <Typography variant="body2" fontSize="0.8rem">
                            R$
                          </Typography>
                          <Typography variant="body2" fontSize="0.8rem" sx={{ textDecoration: 'line-through' }}>
                            {Number(product.amount).toLocaleString('pt-BR', {
                              style: 'decimal',
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </>
                      )}
                      <Typography variant="subtitle1" lineHeight={1.3}>
                        {Number(discountedAmount).toLocaleString(
                          'pt-BR',
                          product.discount > 0
                            ? {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                              }
                            : {
                                style: 'currency',
                                currency: 'BRL',
                              }
                        )}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {bonusAmount > 0 ? (
                        <Typography variant="body2" textTransform="none">
                          +
                          {Number(bonusAmount).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}{' '}
                          de bônus
                        </Typography>
                      ) : (
                        <Typography variant="body2" textTransform="none">
                          Sem bônus
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </ToggleButton>
              )
            })}
          </ToggleButtonGroup>
        )}
        {selectedProductId && (
          <>
            <Divider />
            <ToggleButtonGroup
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
              aria-label="Método de Pagamento"
              exclusive
              fullWidth
            >
              <ToggleButton value="pix" aria-label="Pix" sx={{ width: 'auto', paddingX: 4 }}>
                <Pix sx={{ mr: 1 }} />
                Pix
              </ToggleButton>
              <ToggleButton value="credit_card" aria-label="Cartão de Crédito">
                <CreditCard sx={{ mr: 1 }} />
                Cartão de Crédito
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        )}
        {showCardSelection && (
          <>
            <Divider />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" fontWeight="bold">
                Cartões
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddOutlined />}
                onClick={handleAddCard}
                disableElevation
                sx={{ borderRadius: 4, alignSelf: 'flex-end' }}
              >
                Adicionar Cartão
              </Button>
            </Stack>
            <Divider />
            {isLoadingCards ? (
              <Skeleton variant="rectangular" width="100%" height={200} />
            ) : isErrorCards ? (
              <ErrorDisplay message="Não foi possível carregar seus cartões de crédito." />
            ) : creditCards?.results?.length ? (
              <Stack spacing={2} width="100%">
                {creditCards.results.map((card: PaymentCreditCard) => (
                  <CardItem key={card.id} card={card} selectedCardId={selectedCardId} onSelect={setSelectedCardId} />
                ))}
              </Stack>
            ) : (
              <EmptyState
                title="Ops! Parece que você não tem cartões cadastrados."
                message="Adicione um cartão para começar."
                imageSrc="/assets/png/empty.png"
              />
            )}
          </>
        )}
        {selectedProductId && paymentMethod && (paymentMethod === 'pix' || selectedCardId) && (
          <>
            <Divider />
            <Typography variant="h6" fontWeight="bold">
              Resumo do Pedido
            </Typography>
            {selectedProduct && <OrderSummary product={selectedProduct} />}
            <Divider />
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleConfirmPayment}
              disableElevation
              sx={{ borderRadius: 4 }}
              loading={isSubmitting}
            >
              Confirmar pagamento
            </LoadingButton>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default DepositPage
