import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { HttpError } from '@/api/http'

import { getStations, getStation } from './services'
import { GetStationsPayload, GetStationsResponse, GetStationPayload, GetStationResponse } from './types'

export const useQueryStations = (
  params: GetStationsPayload,
  options?: Omit<UseQueryOptions<GetStationsResponse, HttpError>, 'queryKey' | 'queryFn'>
) => {
  const queryKey: QueryKey = ['stations', params]
  const queryFn = () => getStations(params)

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}

export const useQueryStation = (
  params: GetStationPayload,
  options?: Omit<UseQueryOptions<GetStationResponse, HttpError>, 'queryKey' | 'queryFn'>
) => {
  const queryKey: QueryKey = ['station', params]
  const queryFn = () => getStation(params)

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}
