import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { HttpError } from '@/api/http'

import {
  postPaymentCreditCard,
  deletePaymentCreditCard,
  postPaymentCreditCardMakePaymentById,
  postPaymentCreditCardMakePaymentWithCardData,
  postPaymentPixMakePayment,
  postPaymentProduct,
} from './services'

import {
  PostPaymentCreditCardPayload,
  PostPaymentCreditCardResponse,
  DeletePaymentCreditCardPayload,
  PostPaymentCreditCardMakePaymentByIdPayload,
  PostPaymentCreditCardMakePaymentWithCardDataPayload,
  PostPaymentPixMakePaymentPayload,
  PostPaymentPixMakePaymentResponse,
  PostPaymentProductPayload,
  PostPaymentProductResponse,
} from './types'

export const useMutationPostPaymentCreditCard = (
  options?: UseMutationOptions<PostPaymentCreditCardResponse, HttpError, PostPaymentCreditCardPayload>
) => {
  return useMutation({ ...options, mutationFn: postPaymentCreditCard })
}

export const useMutationDeletePaymentCreditCard = (
  options?: UseMutationOptions<void, HttpError, DeletePaymentCreditCardPayload>
) => {
  return useMutation({ ...options, mutationFn: deletePaymentCreditCard })
}

export const useMutationPostPaymentCreditCardMakePaymentById = (
  options?: UseMutationOptions<void, HttpError, PostPaymentCreditCardMakePaymentByIdPayload>
) => {
  return useMutation({ ...options, mutationFn: postPaymentCreditCardMakePaymentById })
}

export const useMutationPostPaymentCreditCardMakePaymentWithCardData = (
  options?: UseMutationOptions<void, HttpError, PostPaymentCreditCardMakePaymentWithCardDataPayload>
) => {
  return useMutation({ ...options, mutationFn: postPaymentCreditCardMakePaymentWithCardData })
}

export const useMutationPostPaymentPixMakePayment = (
  options?: UseMutationOptions<PostPaymentPixMakePaymentResponse, HttpError, PostPaymentPixMakePaymentPayload>
) => {
  return useMutation({ ...options, mutationFn: postPaymentPixMakePayment })
}

export const useMutationPostPaymentProduct = (
  options?: UseMutationOptions<PostPaymentProductResponse, HttpError, PostPaymentProductPayload>
) => {
  return useMutation({ ...options, mutationFn: postPaymentProduct })
}
