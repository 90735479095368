import React from 'react'

import { createBrowserRouter, Navigate } from 'react-router-dom'

import Root from '@/components/Router/Root'

import MainTemplate from '@/templates/MainTemplate'
import AuthTemplate from '@/templates/AuthTemplate'

import AuthPage from '@/pages/Auth/Auth'
import AuthLoginPage from '@/pages/Auth/Login'
import AuthRegisterPage from '@/pages/Auth/Register'
import AuthForgotPassword from '@/pages/Auth/ForgotPassword'
import AuthNewPassword from '@/pages/Auth/NewPassword'
import CardAdd from '@/pages/Card/Add'
import MapPage from '@/pages/Map'
import StationsPage from '@/pages/Stations'
import StationPage from '@/pages/Station'
import ChargingPointPage from '@/pages/ChargingPoint'
import DepositPage from '@/pages/Deposit'
import WalletPage from '@/pages/Wallet'
import ProfilePage from '@/pages/Profile'
import ErrorPage from '@/pages/Error'

import ProtectedRoute from '@/components/Router/ProtectedRoute'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <MainTemplate />,
        children: [
          {
            path: 'auth',
            element: <AuthTemplate />,
            children: [
              { index: true, element: <AuthPage /> },
              { path: 'login', element: <AuthLoginPage /> },
              { path: 'register', element: <AuthRegisterPage /> },
              { path: 'forgot-password', element: <AuthForgotPassword /> },
              { path: 'new-password', element: <AuthNewPassword /> },
            ],
          },
          { index: true, element: <MapPage /> },
          { path: 'stations', element: <StationsPage /> },
          { path: 'station/:stationId', element: <StationPage /> },
          {
            element: <ProtectedRoute />,
            children: [
              {
                path: 'station/:stationId/charging-point/:chargingPointId',
                element: <ChargingPointPage />,
              },
              { path: 'wallet', element: <WalletPage /> },
              {
                path: 'deposit',
                element: <DepositPage />,
              },
              {
                path: 'card',
                children: [{ path: 'add', element: <CardAdd /> }],
              },
              { path: 'profile', element: <ProfilePage /> },
            ],
          },
          { path: '*', element: <Navigate to="/" replace /> },
        ],
      },
    ],
  },
])

export default router
