import React from 'react'

import MuiTextField, { TextFieldProps } from '@mui/material/TextField'

import { getIn } from 'formik'

import { TextInputProps } from './types'

export function fieldToTextInput({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  ...props
}: TextInputProps): TextFieldProps {
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name)
      },
    ...field,
    ...props,
  }
}

export function TextInput({ children, ...props }: TextInputProps) {
  const defaultProps = {
    size: 'small',
  } as TextFieldProps

  return (
    <MuiTextField {...defaultProps} {...fieldToTextInput(props)}>
      {children}
    </MuiTextField>
  )
}
