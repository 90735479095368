import http from '@/api/http'

import { UserUpdate } from '@/types/User'

import { PatchUserPayload, PatchUserResponse, DeleteUserPayload, DeleteUserResponse } from './types'

export const patchUser = async (payload: PatchUserPayload): Promise<PatchUserResponse> => {
  const { id, ...data } = payload

  const response = await http.patch<PatchUserResponse, UserUpdate>(`/users/${id}/`, data)
  return response.data
}

export const deleteUser = async (payload: DeleteUserPayload): Promise<DeleteUserResponse> => {
  const { id } = payload

  const response = await http.delete<DeleteUserResponse>(`/users/${id}/`)
  return response.data
}
