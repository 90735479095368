import React, { useRef, useState, useEffect, useCallback } from 'react'

import { Geolocation, Position } from '@capacitor/geolocation'

import { Map, AdvancedMarker, AdvancedMarkerAnchorPoint, useMap } from '@vis.gl/react-google-maps'

import { Box } from '@mui/material'

import { useQueryStations } from '@/api/stations/queries'

import { Station, ChargingPointStatus } from '@/types/Station'

import SearchBar from '@/modules/Map/SearchBar'
import LocationFab from '@/modules/Map/LocationFab'

import { useToast } from '@/contexts/ToastContext'
import { useKeyboard } from '@/contexts/KeyboardContext'
import { useStationModal } from '@/contexts/StationModalContext'

import { haversineDistance } from '@/utils/map'

interface MapProps {
  initialLat?: number
  initialLng?: number
}

const MapPage: React.FC<MapProps> = ({ initialLat = -8.052207, initialLng = -34.949004 }) => {
  const map = useMap()
  const { show } = useToast()
  const { isKeyboardVisible } = useKeyboard()
  const { openModal, closeModal } = useStationModal()

  const isProgrammaticallyMovingCamera = useRef(false)

  const [visibleRadius, setVisibleRadius] = useState(0)

  const [selectedStation, setSelectedStation] = useState<Station | null>(null)
  const [currentLocation, setCurrentLocation] = useState<Position | null>(null)
  const [isTrackingCurrentLocation, setIsTrackingCurrentLocation] = useState(false)
  const [isGettingCurrentLocation, setIsGettingCurrentLocation] = useState(false)

  const { data: stations, isLoading: isLoadingStations } = useQueryStations({
    infinite: true,
  })

  const handleOnClickCurrentLocationClick = async () => {
    if (isTrackingCurrentLocation) {
      setIsTrackingCurrentLocation(false)
      return
    }

    try {
      setIsGettingCurrentLocation(true)

      const coordinates = await Geolocation.getCurrentPosition()

      if (map) {
        isProgrammaticallyMovingCamera.current = true

        map.moveCamera({
          center: {
            lat: coordinates.coords.latitude,
            lng: coordinates.coords.longitude,
          },
          zoom: 15,
        })

        isProgrammaticallyMovingCamera.current = false
      }

      setCurrentLocation(coordinates)
      setIsTrackingCurrentLocation(true)
    } catch {
      show('Não foi possível obter a localização atual.', {
        severity: 'error',
      })

      isProgrammaticallyMovingCamera.current = false
    } finally {
      setIsGettingCurrentLocation(false)
    }
  }

  const handleOnCenterChanged = () => {
    if (isProgrammaticallyMovingCamera.current) {
      return
    }

    setIsTrackingCurrentLocation(false)
  }

  const handleOnClickStation = (station: Station) => {
    map?.moveCamera({
      center: {
        lat: station.latitude,
        lng: station.longitude,
      },
      zoom: 16,
    })

    openModal(station, () => setSelectedStation(null))
    setSelectedStation(station)
  }

  const getAccuracyInPx = (currentLocation: Position): number => {
    if (!map) {
      return 0
    }

    const accuracy = currentLocation.coords.accuracy
    const visibleRadiusInPx = (accuracy / visibleRadius) * window.innerHeight

    return visibleRadiusInPx
  }

  const getVisibleRadius = useCallback((): number => {
    if (!map) {
      return 0
    }

    const bounds = map.getBounds()

    if (!bounds) {
      return 0
    }

    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()

    const topLeft = new window.google.maps.LatLng(ne.lat(), sw.lng())
    const bottomRight = new window.google.maps.LatLng(sw.lat(), ne.lng())

    const distance = haversineDistance(topLeft, bottomRight)

    return distance / 2
  }, [map])

  useEffect(() => {
    if (!selectedStation) {
      closeModal()
      return
    }

    handleOnClickStation(selectedStation)
  }, [selectedStation])

  useEffect(() => {
    if (!map) return

    const handleBoundsChanged = () => {
      const radius = getVisibleRadius()

      setVisibleRadius(radius)
    }

    const listener = map.addListener('bounds_changed', handleBoundsChanged)

    handleBoundsChanged()

    return () => {
      window.google.maps.event.removeListener(listener)
    }
  }, [map, getVisibleRadius])

  useEffect(() => {
    if (!map) return

    handleOnClickCurrentLocationClick()
  }, [map])

  return (
    <React.Fragment>
      <SearchBar
        stations={stations?.results || []}
        isLoadingStations={isLoadingStations}
        selectedStation={selectedStation}
        setSelectedStation={setSelectedStation}
      />
      {!isKeyboardVisible && !selectedStation ? (
        <LocationFab
          isTrackingCurrentLocation={isTrackingCurrentLocation}
          isGettingCurrentLocation={isGettingCurrentLocation}
          handleOnClickCurrentLocationClick={handleOnClickCurrentLocationClick}
        />
      ) : null}
      <Map
        mapId={process.env.REACT_APP_GOOGLE_MAPS_MAP_ID}
        defaultCenter={{ lat: initialLat, lng: initialLng }}
        defaultZoom={16}
        minZoom={4}
        maxZoom={20}
        reuseMaps={true}
        clickableIcons={false}
        disableDefaultUI={true}
        onCenterChanged={handleOnCenterChanged}
        style={{
          display: isKeyboardVisible ? 'none' : 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {currentLocation ? (
          <AdvancedMarker
            position={{
              lat: currentLocation.coords.latitude,
              lng: currentLocation.coords.longitude,
            }}
            anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
          >
            <Box
              width={16}
              height={16}
              bgcolor="#4285F4"
              borderRadius="50%"
              sx={{
                border: '2px solid #fff',
                outline: `${getAccuracyInPx(currentLocation)}px solid #1bb6ff55`,
              }}
            />
          </AdvancedMarker>
        ) : null}
        {stations?.results.map((station) => {
          const { charging_points } = station

          const someChargingPointAvailable = charging_points.some(
            (chargingPoint) => chargingPoint.status === ChargingPointStatus.AVAILABLE
          )
          const allChargingPointsInMaintenance = charging_points.every(
            (chargingPoint) => chargingPoint.status === ChargingPointStatus.MAINTENANCE
          )
          const iconPath = someChargingPointAvailable
            ? '/assets/svg/pins/pin-available.svg'
            : allChargingPointsInMaintenance
              ? '/assets/svg/pins/pin-maintenance.svg'
              : '/assets/svg/pins/pin-unavailable.svg'
          const iconSize = someChargingPointAvailable || !allChargingPointsInMaintenance ? 42 : 32

          return (
            <AdvancedMarker
              key={station.id}
              position={{
                lat: station.latitude,
                lng: station.longitude,
              }}
              anchorPoint={AdvancedMarkerAnchorPoint.BOTTOM_CENTER}
              onClick={() => handleOnClickStation(station)}
            >
              <Box component="img" src={iconPath} width={iconSize} height={iconSize} />
            </AdvancedMarker>
          )
        })}
      </Map>
    </React.Fragment>
  )
}

export default MapPage
