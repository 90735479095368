export type Station = {
  id: number
  name: string
  description: string
  address: string
  city: string
  state: string
  zip_code: string
  latitude: number
  longitude: number
  phone: string
  email: string | null
  website: string | null
  image: string | null
  charging_points: ChargingPoint[]
  created_at: string
  updated_at: string
}

export type ChargingPoint = {
  id: number
  status: ChargingPointStatus
  title: string
  description: string
  power: number
  rate_per_kwh: number
  charging_connectors: ChargingConnector[]
  connector_notifications: boolean
  created_at: string
  updated_at: string
}

export enum ChargingPointStatus {
  AVAILABLE = 'AVAILABLE',
  CONNECTED = 'CONNECTED',
  IN_PROGRESS = 'IN_PROGRESS',
  UNAVAILABLE = 'UNAVAILABLE',
  MAINTENANCE = 'MAINTENANCE',
}

export type ChargingConnector = {
  id: number
  connector_type: string
  description: string
  created_at: string
  updated_at: string
}
