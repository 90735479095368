import React, { useState } from 'react'

import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom'

import * as Yup from 'yup'
import { Field, Form, Formik, FormikHelpers } from 'formik'

import { Box, Stack, Typography, Link, Button, Alert, IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

import { TextInput } from '@/components/Inputs/Text'
import { MaskedInput } from '@/components/Inputs/Masked'

import { useAuth } from '@/hooks/useAuth'

import { HttpError } from '@/api/http'

export interface LoginFormData {
  username: string
  password: string
}

const initialValues: LoginFormData = {
  username: '',
  password: '',
}

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .test('is-valid-cpf', 'O CPF informado é inválido', (value) => {
      if (!value) return false

      return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value)
    })
    .required('O campo de CPF é obrigatório'),
  password: Yup.string()
    .min(6, 'A senha deve conter no mínimo 6 caracteres')
    .required('O campo de senha é obrigatório'),
})

const AuthLoginPage: React.FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const { login, isLoading: isLoadingAuth } = useAuth()

  const [loginError, setLoginError] = useState<string | null>(null)
  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  const handleOnSubmitLogin = (values: LoginFormData, { setSubmitting }: FormikHelpers<LoginFormData>) => {
    login(values.username, values.password)
      .then(() => {
        navigate(state?.from ? state.from : '/')
      })
      .catch((error: HttpError) => {
        setLoginError(error.message || 'Ocorreu um erro inesperado ao tentar fazer login! Tente novamente mais tarde.')
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleOnClickRegister = () => {
    navigate('/auth/register', { state })
  }

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={4}
      sx={{ maxWidth: 400, margin: '0 auto' }}
    >
      <Stack direction="column" alignItems="center" textAlign="center">
        <Typography variant="h6" fontWeight="bold">
          Entrar na MicroRED
        </Typography>
        <Typography variant="subtitle1">Carregue fácil, Ande mais!</Typography>
      </Stack>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmitLogin}>
        {({ submitForm }) => (
          <Box component={Form} width="100%">
            <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
              {loginError ? (
                <Alert severity="error" sx={{ width: '100%', alignItems: 'center', borderRadius: 4 }}>
                  {loginError}
                </Alert>
              ) : null}

              <Field
                component={TextInput}
                placeholder="Digite seu CPF"
                name="username"
                autoComplete="cpf"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    mask: '___.___.___-__',
                    replacement: {
                      _: /[0-9]/,
                    },
                  },
                }}
              />
              <Field
                component={TextInput}
                placeholder="Digite sua senha"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <LoadingButton
                variant="contained"
                color="primary"
                fullWidth
                sx={{ borderRadius: 20 }}
                onClick={submitForm}
                loading={isLoadingAuth}
                disabled={isLoadingAuth}
                disableElevation
              >
                Entrar
              </LoadingButton>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                sx={{ borderRadius: 20 }}
                onClick={handleOnClickRegister}
                disabled={isLoadingAuth}
                disableElevation
              >
                Cadastrar-se
              </Button>

              <Stack alignItems="center" justifyContent="center">
                <Link component={RouterLink} to="/auth/forgot-password" state={state} variant="body2" underline="hover">
                  Esqueceu a senha?
                </Link>
              </Stack>
            </Stack>
          </Box>
        )}
      </Formik>
    </Stack>
  )
}

export default AuthLoginPage
