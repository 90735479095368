import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { HttpError } from '@/api/http'

import { getAuthLoginSso, getAuthVerifyEmailRequest } from './services'
import { GetAuthLoginSsoPayload, GetAuthLoginSsoResponse, GetAuthVerifyEmailRequestResponse } from './types'

export const useQueryAuthLoginSso = (
  params: GetAuthLoginSsoPayload,
  options?: Omit<UseQueryOptions<GetAuthLoginSsoResponse, HttpError>, 'queryKey'>
) => {
  const queryKey: QueryKey = ['auth-login-sso', params]
  const queryFn = () => getAuthLoginSso(params)

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}

export const useQueryAuthVerifyEmailRequest = (
  options?: Omit<UseQueryOptions<GetAuthVerifyEmailRequestResponse, HttpError>, 'queryKey'>
) => {
  const queryKey: QueryKey = ['auth-verify-email-request']
  const queryFn = () => getAuthVerifyEmailRequest()

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}
