import { CapacitorHttp, HttpHeaders } from '@capacitor/core'
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin'

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export interface RequestOptions<D = undefined, P = undefined> {
  method: HttpMethod
  url: string
  params?: P
  data?: D
  headers?: Record<string, string>
}

export type APIError =
  | {
      type: string
      errors: {
        code: string
        detail: string
        attr: null
      }[]
    }
  | { message: string }

export interface Response<R> {
  data: R
  status: number
  headers: HttpHeaders
  url: string
}

export class HttpError extends Error {
  public status: number

  constructor(status: number, message: string) {
    super(message)

    this.status = status
  }
}

const TOKEN_KEY = 'auth_token'

class HttpService {
  private baseUrl: string

  constructor() {
    const base = process.env.REACT_APP_API_URL

    if (!base) {
      throw new HttpError(-1, 'REACT_APP_API_URL não está definido nas variáveis de ambiente.')
    }

    this.baseUrl = base
  }

  private removeUndefined(obj: unknown): unknown {
    if (Array.isArray(obj)) {
      return obj.map((item) => this.removeUndefined(item))
    } else if (obj !== null && typeof obj === 'object') {
      return Object.entries(obj)
        .filter(([, value]) => value !== undefined)
        .reduce(
          (acc, [key, value]) => {
            acc[key] = this.removeUndefined(value)
            return acc
          },
          {} as Record<string, unknown>
        )
    }

    return obj
  }

  private serializeParams(params: unknown): Record<string, string> | undefined {
    if (typeof params !== 'object' || params === null) {
      return undefined
    }

    const serialized: Record<string, string> = {}
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        serialized[key] = String(value)
      }
    })
    return serialized
  }

  private handleErrors(response: Response<APIError>): void {
    const { status, data } = response

    if (status >= 400 && status < 500) {
      throw new HttpError(
        status,
        'errors' in data && data.errors.length > 0
          ? data.errors[0].detail
          : 'message' in data
            ? data.message
            : 'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.'
      )
    } else if (status >= 500) {
      throw new HttpError(
        status,
        'errors' in data && data.errors.length > 0
          ? data.errors[0].detail
          : 'message' in data
            ? data.message
            : 'Ocorreu um erro interno no servidor. Por favor, tente novamente mais tarde.'
      )
    }
  }

  public async request<R = unknown, D = undefined, P = undefined>(options: RequestOptions<D, P>): Promise<Response<R>> {
    let token: string | null = null
    try {
      const result = await SecureStoragePlugin.get({
        key: TOKEN_KEY,
      })

      token = result.value
    } catch {
      token = null
    }

    try {
      const cleanedParams = options.params ? this.serializeParams(this.removeUndefined(options.params)) : undefined
      const cleanedData = options.data ? this.removeUndefined(options.data) : undefined

      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(options.headers || {}),
      }

      if (token) {
        headers['Authorization'] = `Bearer ${token}`
      }

      const response = await CapacitorHttp.request({
        method: options.method,
        url: `${this.baseUrl}${options.url}`,
        params: cleanedParams,
        data: cleanedData,
        headers,
      })

      const isAuthRoute = options.url.startsWith('auth') || options.url.startsWith('/auth')
      if (response.status === 401 && !isAuthRoute) {
        window.location.href = `/auth?error=unauthorized&redirect=${encodeURIComponent(window.location.pathname)}`

        return response as Response<R>
      }

      if (response.status === 403 && !isAuthRoute) {
        window.location.href = '/?error=forbidden'

        return response as Response<R>
      }

      this.handleErrors(response)

      return response as Response<R>
    } catch (error: unknown) {
      if (!(error instanceof HttpError)) {
        throw new HttpError(-1, 'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.')
      }

      throw error
    }
  }

  public get<R = unknown, P = undefined>(url: string, params?: P): Promise<Response<R>> {
    return this.request<R, undefined, P>({
      method: 'GET',
      url,
      params,
    })
  }

  public post<R = unknown, D = unknown>(url: string, data?: D): Promise<Response<R>> {
    return this.request<R, D, undefined>({
      method: 'POST',
      url,
      data,
    })
  }

  public put<R = unknown, D = unknown>(url: string, data?: D): Promise<Response<R>> {
    return this.request<R, D, undefined>({
      method: 'PUT',
      url,
      data,
    })
  }

  public patch<R = unknown, D = unknown, P = undefined>(url: string, data?: D, params?: P): Promise<Response<R>> {
    return this.request<R, D, P>({
      method: 'PATCH',
      url,
      data,
      params,
    })
  }

  public delete<R = unknown, P = undefined>(url: string, params?: P): Promise<Response<R>> {
    return this.request<R, undefined, P>({
      method: 'DELETE',
      url,
      params,
    })
  }
}

const http = new HttpService()

export default http
